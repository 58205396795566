export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  Order: any;
};

export type ActiveOrder = {
  __typename?: 'ActiveOrder';
  accepted_at?: Maybe<Scalars['Float']>;
  brand_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  brand_name?: Maybe<Scalars['String']>;
  cooking_at?: Maybe<Scalars['Float']>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  delivery_address?: Maybe<DeliveryAddress>;
  delivery_status?: Maybe<DeliveryStatus>;
  external_id?: Maybe<Scalars['String']>;
  guest_feedback_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_pickup?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationV2>;
  pickup_time?: Maybe<Scalars['Float']>;
  prep_time?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type AddCreditsToLoyaltyAccountInput = {
  credits?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type AddItemInput = {
  cart_id?: InputMaybe<Scalars['String']>;
  item?: InputMaybe<Scalars['JSON']>;
  location_slug?: InputMaybe<Scalars['String']>;
};

export type AddLocationInput = {
  are_drinks_self_serve?: InputMaybe<Scalars['Boolean']>;
  google_place_id?: InputMaybe<Scalars['String']>;
  guest_experience_phone_number?: InputMaybe<Scalars['String']>;
  is_dine_in_available?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  share_link?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  stripe_location_id?: InputMaybe<Scalars['String']>;
  tax_rate?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['String']>;
  toast_location_id?: InputMaybe<Scalars['String']>;
  total_kiosks?: InputMaybe<Scalars['Int']>;
  yelp_location_id?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type AddressAndDeliveryEstimates = {
  __typename?: 'AddressAndDeliveryEstimates';
  address?: Maybe<AddressDetails>;
  delivery_address_id?: Maybe<Scalars['String']>;
  delivery_estimates?: Maybe<Array<Maybe<DeliveryEstimate>>>;
};

export type AddressDetails = {
  __typename?: 'AddressDetails';
  city?: Maybe<Scalars['String']>;
  geocodes?: Maybe<GeocodeDetails>;
  id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AllLocationDetailOutput = {
  __typename?: 'AllLocationDetailOutput';
  are_drinks_self_serve?: Maybe<Scalars['Boolean']>;
  google_place_id?: Maybe<Scalars['String']>;
  guest_experience_phone_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_dine_in_available?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  service_fee_rate?: Maybe<Scalars['Int']>;
  settings?: Maybe<LocationSettings>;
  share_link?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  stripe_location_id?: Maybe<Scalars['String']>;
  tax_rate?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  toast_location_id?: Maybe<Scalars['String']>;
  total_kiosks?: Maybe<Scalars['Int']>;
  yelp_location_id?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type Allergen = {
  __typename?: 'Allergen';
  allergen_id?: Maybe<Scalars['String']>;
  allergen_name?: Maybe<Scalars['String']>;
  allergen_synonymous?: Maybe<Scalars['String']>;
};

export type ApplyPromoCodeInput = {
  cart_id?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
};

export type AttachReceiptEmailPayload = {
  __typename?: 'AttachReceiptEmailPayload';
  id?: Maybe<Scalars['String']>;
};

export type AutoDeactivatedItem = {
  __typename?: 'AutoDeactivatedItem';
  item_id?: Maybe<Scalars['Int']>;
  item_type?: Maybe<Scalars['String']>;
};

export type AvailableOrderTimes = {
  __typename?: 'AvailableOrderTimes';
  date?: Maybe<Scalars['String']>;
  is_asap_available?: Maybe<Scalars['Boolean']>;
  times?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']>>>>>;
};

export type Banner = {
  __typename?: 'Banner';
  background_color?: Maybe<Scalars['String']>;
  brands?: Maybe<Array<Maybe<OperationsBrand>>>;
  desktop: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_new_user?: Maybe<Scalars['Boolean']>;
  locations?: Maybe<Array<Maybe<LocationV2>>>;
  mobile: Scalars['String'];
  platforms?: Maybe<Array<Maybe<PlatformsEnum>>>;
  start_time?: Maybe<Scalars['String']>;
  store_status?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  visibility?: Maybe<Array<Maybe<BannerVisibilityEnum>>>;
};

export enum BannerVisibilityEnum {
  Anonymous = 'anonymous',
  LoggedIn = 'logged_in'
}

export type BrandCreateInput = {
  about?: InputMaybe<Scalars['String']>;
  about_media_ids: Array<Scalars['Int']>;
  cuisine?: InputMaybe<Scalars['String']>;
  feature_body: Scalars['String'];
  feature_header: Scalars['String'];
  /** Base 64 Image */
  feature_image?: InputMaybe<Scalars['String']>;
  /** Base 64 Image */
  food_hero_image?: InputMaybe<Scalars['String']>;
  gallery_media_ids: Array<Scalars['Int']>;
  /** Base 64 Image */
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  short_cuisine?: InputMaybe<Scalars['String']>;
  tagline: Scalars['String'];
  ticker: Scalars['String'];
  typ: BrandType;
  url?: InputMaybe<Scalars['String']>;
};

export enum BrandType {
  Codevelopment = 'CODEVELOPMENT',
  Partnership = 'PARTNERSHIP'
}

export type BrandUpdateInput = {
  about?: InputMaybe<Scalars['String']>;
  about_media_ids: Array<Scalars['Int']>;
  cuisine?: InputMaybe<Scalars['String']>;
  feature_body: Scalars['String'];
  feature_header: Scalars['String'];
  /** Base 64 Image */
  feature_image?: InputMaybe<Scalars['String']>;
  /** Base 64 Image */
  food_hero_image?: InputMaybe<Scalars['String']>;
  food_hero_image_blurhash?: InputMaybe<Scalars['String']>;
  food_hero_image_url?: InputMaybe<Scalars['String']>;
  gallery_media_ids: Array<Scalars['Int']>;
  /** Base 64 Image */
  logo?: InputMaybe<Scalars['String']>;
  logo_blurhash?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  short_cuisine?: InputMaybe<Scalars['String']>;
  tagline: Scalars['String'];
  ticker?: InputMaybe<Scalars['String']>;
  typ: BrandType;
  url?: InputMaybe<Scalars['String']>;
};

export type BrandV2 = {
  __typename?: 'BrandV2';
  cuisine?: Maybe<Scalars['String']>;
  food_hero_image?: Maybe<Image>;
  id?: Maybe<Scalars['Int']>;
  logo?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
};

export type BuildNote = {
  __typename?: 'BuildNote';
  custom_chit?: Maybe<CustomChit>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  modifier_list_id_order_override?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  removal_modifier_id?: Maybe<Scalars['Int']>;
  show_in_kds?: Maybe<Scalars['Boolean']>;
  show_in_receipt?: Maybe<Scalars['Boolean']>;
};

export type BuildNotesInput = {
  custom_chit?: InputMaybe<CustomChitInput>;
  id?: InputMaybe<Scalars['Int']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
  modifier_list_id_order_override?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  removal_modifier_id?: InputMaybe<Scalars['Int']>;
  show_in_kds?: InputMaybe<Scalars['Boolean']>;
  show_in_receipt?: InputMaybe<Scalars['Boolean']>;
};

export type BuildNotesOutput = {
  __typename?: 'BuildNotesOutput';
  id?: Maybe<Scalars['Int']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  modifier_list_id_order_override?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  removal_modifier_id?: Maybe<Scalars['Int']>;
  show_in_kds?: Maybe<Scalars['Boolean']>;
  show_in_receipt?: Maybe<Scalars['Boolean']>;
};

export type BuildStep = {
  __typename?: 'BuildStep';
  additive_modifier_item_id?: Maybe<Scalars['String']>;
  build_line_text?: Maybe<Scalars['String']>;
  instructional_text?: Maybe<Scalars['String']>;
  instructional_text_spanish?: Maybe<Scalars['String']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  menu_item_name?: Maybe<Scalars['String']>;
  removal_modifier_item_id?: Maybe<Scalars['String']>;
  step_ordinal?: Maybe<Scalars['Float']>;
};

export type Cta = {
  __typename?: 'CTA';
  text: Scalars['String'];
  url: Scalars['String'];
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Cart = {
  __typename?: 'Cart';
  available_order_times?: Maybe<Array<Maybe<AvailableOrderTimes>>>;
  coupon?: Maybe<Coupon>;
  credits_used?: Maybe<Scalars['Int']>;
  customer_delivery_fee?: Maybe<Scalars['Int']>;
  customer_id?: Maybe<Scalars['Int']>;
  delivery_address?: Maybe<DeliveryAddress>;
  delivery_time_estimate?: Maybe<Scalars['Int']>;
  dining_option?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['String']>;
  is_delivery?: Maybe<Scalars['Boolean']>;
  is_group_order: Scalars['Boolean'];
  is_scheduled?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  last_name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  mandatory_advanced_scheduling?: Maybe<MandatoryAdvancedScheduling>;
  name?: Maybe<Scalars['String']>;
  order_time?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<Maybe<Participant>>>;
  phone_number?: Maybe<Scalars['String']>;
  pickup_type?: Maybe<PickupType>;
  points_earned?: Maybe<Scalars['Int']>;
  reward_amount?: Maybe<Scalars['Int']>;
  spend_limit?: Maybe<Scalars['Int']>;
  start_buffer_time?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  store_location?: Maybe<LocationV2>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['Int']>;
  tip_rate?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
  use_bag?: Maybe<Scalars['Boolean']>;
  validation?: Maybe<CartValidation>;
};

export type CartMenuItemModifier = {
  __typename?: 'CartMenuItemModifier';
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type CartReceipt = {
  __typename?: 'CartReceipt';
  coupon?: Maybe<Coupon>;
  id: Scalars['String'];
  line_items?: Maybe<Array<Maybe<CartReceiptLineItem>>>;
  loyalty?: Maybe<CartReceiptLoyalty>;
  reward?: Maybe<Reward>;
};

export type CartReceiptLineItem = {
  __typename?: 'CartReceiptLineItem';
  amount: Scalars['Float'];
  display_amount: Scalars['String'];
  external_id?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  include_on_cart_receipt?: Maybe<Scalars['Boolean']>;
  is_cpg?: Maybe<Scalars['Boolean']>;
  is_free?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  line_item_type: CartReceiptLineItemType;
  modifier_text?: Maybe<Scalars['String']>;
  ordinal?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Int']>;
  slashed_amount?: Maybe<Scalars['String']>;
  store_menu_item_id?: Maybe<Scalars['Int']>;
  tax_rate?: Maybe<Scalars['Int']>;
  tooltip_description?: Maybe<Scalars['String']>;
};

export enum CartReceiptLineItemType {
  MenuItem = 'MenuItem',
  PromotionalLineItem = 'PromotionalLineItem',
  ReceiptLineItem = 'ReceiptLineItem',
  Total = 'Total'
}

export type CartReceiptLoyalty = {
  __typename?: 'CartReceiptLoyalty';
  credits_earned?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  pending_credits?: Maybe<Scalars['Int']>;
  pending_points?: Maybe<Scalars['Int']>;
  points_earned?: Maybe<Scalars['Int']>;
  points_to_next_reward?: Maybe<Scalars['Int']>;
  reward_amount?: Maybe<Scalars['Int']>;
  unlocked_coupon?: Maybe<Coupon>;
  unlocked_reward?: Maybe<Reward>;
};

export type CartValidation = {
  __typename?: 'CartValidation';
  invalid_items?: Maybe<Array<Maybe<CartValidationItem>>>;
  is_items_valid?: Maybe<Scalars['Boolean']>;
  is_order_time_valid?: Maybe<Scalars['Boolean']>;
  is_promo_valid?: Maybe<Scalars['Boolean']>;
  is_store_status_valid?: Maybe<Scalars['Boolean']>;
};

export type CartValidationItem = {
  __typename?: 'CartValidationItem';
  blurhash?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_store_active?: Maybe<Scalars['Boolean']>;
  modifier_items?: Maybe<Array<Maybe<CartValidationItemModifier>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type CartValidationItemModifier = {
  __typename?: 'CartValidationItemModifier';
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type CateringAdjustment = {
  __typename?: 'CateringAdjustment';
  adjusted_subtotal?: Maybe<Scalars['Int']>;
  store_order_id?: Maybe<Scalars['Int']>;
};

export type Chit = {
  __typename?: 'Chit';
  brand: Scalars['String'];
  customer: Scalars['String'];
  id: Scalars['Int'];
  lines: Array<ChitLine>;
  ticketCounter: Scalars['Int'];
};

export type ChitLine = {
  __typename?: 'ChitLine';
  line: Scalars['String'];
  type: Scalars['String'];
};

export type ClaimedOrder = {
  __typename?: 'ClaimedOrder';
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['String']>;
  loyalty_points?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  store_order_id?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ComingSoon = {
  __typename?: 'ComingSoon';
  brand_slug?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  is_auth?: Maybe<Scalars['Boolean']>;
  location_slug?: Maybe<Scalars['String']>;
  notifyDescriptionApp: Scalars['String'];
  notifyDescriptionWeb: Scalars['String'];
  notifyMeBodyApp: Scalars['String'];
  notifyMeBodyWeb: Scalars['String'];
  notifyMeHeaderApp: Scalars['String'];
  notifyMeHeaderWeb: Scalars['String'];
  notifyMePlaceholderApp: Scalars['String'];
  notifyMePlaceholderWeb: Scalars['String'];
  notifyMeTitleApp: Scalars['String'];
  notifyMeTitleWeb: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type ComingSoonInput = {
  brand_slug?: InputMaybe<Scalars['String']>;
  is_auth?: InputMaybe<Scalars['Boolean']>;
  location_slug?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
};

export type ContentfulImage = {
  __typename?: 'ContentfulImage';
  url?: Maybe<Scalars['String']>;
};

export type Coordinate = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  brand_attrs?: Maybe<Scalars['JSON']>;
  code?: Maybe<Scalars['String']>;
  coupon_type?: Maybe<Scalars['String']>;
  credits?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  discount_rate?: Maybe<Scalars['Int']>;
  display_name?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['String']>;
  free_item_minimum_subtotal?: Maybe<Scalars['Int']>;
  free_item_ranks?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['Float']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_discoverable?: Maybe<Scalars['Boolean']>;
  is_fundraiser?: Maybe<Scalars['Boolean']>;
  location_attrs?: Maybe<Scalars['JSON']>;
  loyalty_account_attrs?: Maybe<Scalars['JSON']>;
  max_amount?: Maybe<Scalars['Int']>;
  minimum_cart_items?: Maybe<Scalars['Int']>;
  minimum_subtotal?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order_history_attrs?: Maybe<Scalars['JSON']>;
  users_subset?: Maybe<UsersSubset>;
};

export type Coupons = {
  __typename?: 'Coupons';
  coupons?: Maybe<Array<Maybe<Coupon>>>;
  limit?: Maybe<Scalars['Int']>;
  page_number?: Maybe<Scalars['Int']>;
  total_coupon_count?: Maybe<Scalars['Int']>;
};

export type CouponsInput = {
  code?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page_number?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type CourierV2 = {
  __typename?: 'CourierV2';
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

export type CreateCartInput = {
  customer_id?: InputMaybe<Scalars['Int']>;
  delivery_address_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  is_group_order?: InputMaybe<Scalars['Boolean']>;
  is_scheduled?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  location: Scalars['String'];
  order_time?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  tip_rate?: InputMaybe<Scalars['Int']>;
};

export type CreateCateringOrderInput = {
  customer_delivery_fee?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  invoice_paid_at?: InputMaybe<Scalars['String']>;
  service_fee?: InputMaybe<Scalars['Int']>;
  service_fee_rate?: InputMaybe<Scalars['Int']>;
  subtotal?: InputMaybe<Scalars['Int']>;
  tax?: InputMaybe<Scalars['Int']>;
  tax_rate?: InputMaybe<Scalars['Int']>;
  tip?: InputMaybe<Scalars['Int']>;
  tip_rate?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
};

export type CreateCouponInput = {
  brand_attrs?: InputMaybe<Scalars['JSON']>;
  code?: InputMaybe<Scalars['String']>;
  coupon_type?: InputMaybe<Scalars['String']>;
  credits?: InputMaybe<Scalars['Int']>;
  discount_rate?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['String']>;
  free_item_minimum_subtotal?: InputMaybe<Scalars['Int']>;
  free_item_ranks?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_fundraiser?: InputMaybe<Scalars['Boolean']>;
  location_attrs?: InputMaybe<Scalars['JSON']>;
  loyalty_account_attrs?: InputMaybe<Scalars['JSON']>;
  max_amount?: InputMaybe<Scalars['Int']>;
  minimum_cart_items?: InputMaybe<Scalars['Int']>;
  minimum_subtotal?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order_history_attrs?: InputMaybe<Scalars['JSON']>;
  users_subset?: InputMaybe<UsersSubset>;
};

export type CreateMarketingBillboardInput = {
  background_color: Scalars['String'];
  description: Scalars['String'];
  end_time?: InputMaybe<Scalars['String']>;
  image: Scalars['String'];
  location_ids?: InputMaybe<Array<Scalars['Int']>>;
  platforms?: InputMaybe<Array<InputMaybe<PlatformsEnum>>>;
  portrait_image?: InputMaybe<Scalars['String']>;
  redirect?: InputMaybe<MarketingBillboardRedirectInput>;
  start_time?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  visibility?: InputMaybe<Array<InputMaybe<BannerVisibilityEnum>>>;
};

export type CreateTagInput = {
  collection_name?: InputMaybe<Scalars['String']>;
  /** Base 64 Image */
  hero_image?: InputMaybe<Scalars['String']>;
  hero_image_blurhash?: InputMaybe<Scalars['String']>;
  hero_image_blurhash_v2?: InputMaybe<Scalars['String']>;
  hero_image_url?: InputMaybe<Scalars['String']>;
  hero_image_url_v2?: InputMaybe<Scalars['String']>;
  hero_image_v2?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_collection?: InputMaybe<Scalars['Boolean']>;
  is_filter?: InputMaybe<Scalars['Boolean']>;
  is_v2: Scalars['Boolean'];
  /** Base 64 Image */
  logo?: InputMaybe<Scalars['String']>;
  logo_blurhash?: InputMaybe<Scalars['String']>;
  logo_blurhash_v2?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  logo_url_v2?: InputMaybe<Scalars['String']>;
  logo_v2?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ordinal?: InputMaybe<Scalars['Int']>;
};

export type CustomChit = {
  __typename?: 'CustomChit';
  id?: Maybe<Scalars['Int']>;
  kitchen_name?: Maybe<Scalars['String']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  menu_items?: Maybe<Array<Maybe<MenuItemV2>>>;
};

export type CustomChitInput = {
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  kitchen_name?: InputMaybe<Scalars['String']>;
  menu_item_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  target_class?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Scalars['Int']>;
};

export type CustomChitOutput = {
  __typename?: 'CustomChitOutput';
  id?: Maybe<Scalars['Int']>;
};

export enum CustomChitStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Ready = 'READY',
  Received = 'RECEIVED'
}

export type CustomChitTicket = {
  __typename?: 'CustomChitTicket';
  custom_chit_id?: Maybe<Scalars['Int']>;
  custom_chit_status?: Maybe<CustomChitStatus>;
  custom_chit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_completed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  store_order_line_item_id?: Maybe<Scalars['Int']>;
  store_order_line_item_prep_station_ticket_id?: Maybe<Scalars['Int']>;
};

export type CustomChitTicketInput = {
  custom_chit_id?: InputMaybe<Scalars['Int']>;
  custom_chit_prep_station_ticket_id?: InputMaybe<Scalars['Int']>;
  custom_chit_status?: InputMaybe<CustomChitStatus>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  prep_station_id?: InputMaybe<Scalars['Int']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_prep_station_ticket_id?: InputMaybe<Scalars['Int']>;
};

export type CustomChitTicketReadyInput = {
  custom_chit_id?: InputMaybe<Scalars['Int']>;
  custom_chit_prep_station_ticket_id?: InputMaybe<Scalars['Int']>;
  custom_chit_status?: InputMaybe<CustomChitStatus>;
  is_completed?: InputMaybe<Scalars['Boolean']>;
  prep_station_id?: InputMaybe<Scalars['Int']>;
  print_chits?: InputMaybe<Scalars['Boolean']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_prep_station_ticket_id?: InputMaybe<Scalars['Int']>;
};

export type CustomHours = {
  __typename?: 'CustomHours';
  hours?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  override_date?: Maybe<Scalars['String']>;
  store_id?: Maybe<Scalars['Int']>;
};

export type CustomHoursInput = {
  day?: InputMaybe<Scalars['Int']>;
  hours?: InputMaybe<Scalars['JSON']>;
  month?: InputMaybe<Scalars['Int']>;
  store_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  year?: InputMaybe<Scalars['Int']>;
};

/** Deprecated: This is managed by Shop Backend */
export type Customer = {
  __typename?: 'Customer';
  apple?: Maybe<Scalars['Boolean']>;
  auth0_user_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expo_push_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  google?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['String']>;
  is_new_user?: Maybe<Scalars['Boolean']>;
  is_phone_number_verified?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  last_order_location?: Maybe<LocationV2>;
  location?: Maybe<LocationV2>;
  loyalty_account_id?: Maybe<Scalars['Int']>;
  lunchbox_id?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  recently_ordered?: Maybe<Array<Maybe<PublicMenuItem>>>;
  should_verify_phone_number?: Maybe<Scalars['Boolean']>;
  signup_provider_id?: Maybe<Scalars['Int']>;
  stored_payment_methods?: Maybe<Array<Maybe<StoredPaymentMethod>>>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  unclaimed_orders?: Maybe<Array<Maybe<UnclaimedOrder>>>;
};


/** Deprecated: This is managed by Shop Backend */
export type CustomerRecently_OrderedArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
};

export type CustomerReward = {
  __typename?: 'CustomerReward';
  customer?: Maybe<Customer>;
  id?: Maybe<Scalars['Int']>;
  reward?: Maybe<Reward>;
  store_order?: Maybe<OrderV2>;
};

export type CustomerV2 = {
  __typename?: 'CustomerV2';
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
};

export type Customers = {
  __typename?: 'Customers';
  customers?: Maybe<Array<Maybe<Customer>>>;
  limit?: Maybe<Scalars['Int']>;
  page_number?: Maybe<Scalars['Int']>;
  total_customer_count?: Maybe<Scalars['Int']>;
};

export type CustomersInput = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page_number?: InputMaybe<Scalars['Int']>;
  phone_number?: InputMaybe<Scalars['String']>;
};

export type DeleteAccountInput = {
  email?: InputMaybe<Scalars['String']>;
};

export type DeleteAccountOutput = {
  __typename?: 'DeleteAccountOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteCouponInput = {
  coupon_id?: InputMaybe<Scalars['Int']>;
};

export type DeleteGuestAccountInput = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type DeleteGuestAccountOutput = {
  __typename?: 'DeleteGuestAccountOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  city?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['Int']>;
  geocodes?: Maybe<GeocodeDetails>;
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  is_default?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type DeliveryAddressInput = {
  cart_id?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type DeliveryEstimate = {
  __typename?: 'DeliveryEstimate';
  delivery_time?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  pickup_time?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type DeliveryStatus = {
  __typename?: 'DeliveryStatus';
  estimated_delivery_time?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DeliveryValidation = {
  __typename?: 'DeliveryValidation';
  valid?: Maybe<Scalars['Boolean']>;
};

export type DisplayHours = {
  __typename?: 'DisplayHours';
  date?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
};

export type Eta = {
  __typename?: 'ETA';
  base_eta?: Maybe<Scalars['Int']>;
  default_display?: Maybe<Scalars['String']>;
  is_late?: Maybe<Scalars['Boolean']>;
  late_text?: Maybe<Scalars['String']>;
  time_remaining?: Maybe<Scalars['Int']>;
  time_remaining_lower_bound?: Maybe<Scalars['Int']>;
  time_remaining_upper_bound?: Maybe<Scalars['Int']>;
};

export type EditorialMenuItem = {
  __typename?: 'EditorialMenuItem';
  blurhash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ordinal?: Maybe<Scalars['Int']>;
};

export type EditorialModule = {
  __typename?: 'EditorialModule';
  end_date?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  is_active: Scalars['Boolean'];
  location?: Maybe<LocationV2>;
  menu_items: Array<PublicMenuItem>;
  ordinal: Scalars['Int'];
  platforms?: Maybe<Array<Maybe<PlatformsEnum>>>;
  start_date?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
};


export type EditorialModuleMenu_ItemsArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
};

export type Employee = {
  __typename?: 'Employee';
  completed_item_count?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  employee_login_id?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  pin_code?: Maybe<Scalars['String']>;
};

export type EmployeeInput = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  pin_code?: InputMaybe<Scalars['String']>;
};

export type EmployeesInput = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page_number?: InputMaybe<Scalars['Int']>;
  pin_code?: InputMaybe<Scalars['String']>;
};

export type EmployeesOutput = {
  __typename?: 'EmployeesOutput';
  employees?: Maybe<Array<Maybe<Employee>>>;
  limit?: Maybe<Scalars['Int']>;
  page_number?: Maybe<Scalars['Int']>;
  total_employees_count?: Maybe<Scalars['Int']>;
};

export type FaqCollectionItems = {
  __typename?: 'FaqCollectionItems';
  items?: Maybe<Array<Maybe<LegacyFaq>>>;
};

export type FulfillInPersonOrderPayload = {
  __typename?: 'FulfillInPersonOrderPayload';
  status?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GeneratedChit = {
  __typename?: 'GeneratedChit';
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type GenericStoreHoursInput = {
  day_of_week?: InputMaybe<Scalars['Int']>;
  hours?: InputMaybe<Array<InputMaybe<ServiceHoursInput>>>;
};

export type GeocodeDetails = {
  __typename?: 'GeocodeDetails';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GooglePlaceGeocode = {
  __typename?: 'GooglePlaceGeocode';
  description?: Maybe<Scalars['String']>;
  matched_substrings?: Maybe<Array<Maybe<GooglePlaceMatchedSubstring>>>;
  place_id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  structured_formatting?: Maybe<GooglePlaceStructuredFormatting>;
  terms?: Maybe<Array<Maybe<GooglePlaceTerm>>>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GooglePlaceGeocodeCoord = {
  __typename?: 'GooglePlaceGeocodeCoord';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GooglePlaceMatchedSubstring = {
  __typename?: 'GooglePlaceMatchedSubstring';
  length?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GooglePlaceStructuredFormatting = {
  __typename?: 'GooglePlaceStructuredFormatting';
  main_text?: Maybe<Scalars['String']>;
  main_text_matched_substrings?: Maybe<Array<Maybe<GooglePlaceMatchedSubstring>>>;
  secondary_text?: Maybe<Scalars['String']>;
};

export type GooglePlaceTerm = {
  __typename?: 'GooglePlaceTerm';
  offset?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type GuestFeedbackChannel = {
  __typename?: 'GuestFeedbackChannel';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export enum GuestOrderType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export type Image = {
  __typename?: 'Image';
  blurhash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type Ingredient = {
  __typename?: 'Ingredient';
  allergens?: Maybe<Array<Maybe<Allergen>>>;
  container_amount?: Maybe<Scalars['Float']>;
  container_name?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['Int']>;
  ingredient_id?: Maybe<Scalars['String']>;
  ingredient_name?: Maybe<Scalars['String']>;
  mass_amount?: Maybe<Scalars['Float']>;
  mass_unit?: Maybe<Scalars['String']>;
  prepaction_id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  uom_dimension?: Maybe<Scalars['String']>;
  uom_name?: Maybe<Scalars['String']>;
  volume_amount?: Maybe<Scalars['Float']>;
  volume_name?: Maybe<Scalars['String']>;
};

export type IsReadiable = {
  __typename?: 'IsReadiable';
  non_ready_line_items?: Maybe<Array<Maybe<LineItemV2>>>;
  status?: Maybe<Scalars['Boolean']>;
};

export type IssueCategory = {
  __typename?: 'IssueCategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type JoinGroupOrderInput = {
  customer_id?: InputMaybe<Scalars['Int']>;
  first_name: Scalars['String'];
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
};

export type KdsTicket = {
  __typename?: 'KDSTicket';
  are_all_items_received?: Maybe<Scalars['Boolean']>;
  cooking_at?: Maybe<Scalars['Date']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_single_station_ticket?: Maybe<Scalars['Boolean']>;
  line_items?: Maybe<Array<Maybe<KdsTicketLineItem>>>;
  pickup_time?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  ticket_counter?: Maybe<Scalars['Int']>;
};

export type KdsTicketLineItem = {
  __typename?: 'KDSTicketLineItem';
  id?: Maybe<Scalars['Int']>;
  mapped_prep_stations?: Maybe<Array<Maybe<Scalars['Int']>>>;
  mark_received?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  print_chit?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  sub_line_items?: Maybe<Array<Maybe<KdsTicketSubLineItem>>>;
  target_id?: Maybe<Scalars['Int']>;
  target_type?: Maybe<Scalars['String']>;
};

export type KdsTicketSubLineItem = {
  __typename?: 'KDSTicketSubLineItem';
  id?: Maybe<Scalars['Int']>;
  mark_received?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  print_chit?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['Int']>;
  target_type?: Maybe<Scalars['String']>;
};

export type KichenNamesInput = {
  items?: InputMaybe<Array<InputMaybe<OperationsItemInput>>>;
};

export type KioskCart = {
  id?: InputMaybe<Scalars['String']>;
  line_items?: InputMaybe<Array<InputMaybe<KioskLineItem>>>;
};

export type KioskInvalidItem = {
  __typename?: 'KioskInvalidItem';
  brand_id?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_store_active?: Maybe<Scalars['Boolean']>;
  modifier_items?: Maybe<Array<Maybe<KioskInvalidModifier>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type KioskInvalidModifier = {
  __typename?: 'KioskInvalidModifier';
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type KioskLineItem = {
  brand_name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_added?: InputMaybe<Scalars['Boolean']>;
  is_checkout_carousel_item?: InputMaybe<Scalars['Boolean']>;
  modifier_items?: InputMaybe<Array<InputMaybe<KioskModifierItem>>>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type KioskModifierItem = {
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  modifier_list_id?: InputMaybe<Scalars['Int']>;
  modifier_list_index?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type KiosksStatus = {
  __typename?: 'KiosksStatus';
  online_kiosks?: Maybe<Scalars['Int']>;
  total_kiosks?: Maybe<Scalars['Int']>;
};

export type LandingPageBrandSection = {
  __typename?: 'LandingPageBrandSection';
  brandId?: Maybe<Scalars['Int']>;
  brandName?: Maybe<Scalars['String']>;
  brandSlug?: Maybe<Scalars['String']>;
  cuisine?: Maybe<Scalars['String']>;
  foodHeroImage?: Maybe<ContentfulImage>;
  logo?: Maybe<ContentfulImage>;
  popularTitle?: Maybe<Scalars['String']>;
  roundLogo?: Maybe<ContentfulImage>;
  shortCuisine?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['Int']>;
};

export type LandingPageBrandSectionItems = {
  __typename?: 'LandingPageBrandSectionItems';
  items?: Maybe<Array<Maybe<LandingPageBrandSection>>>;
};

export type LegacyFaq = {
  __typename?: 'LegacyFaq';
  answer?: Maybe<LegacyFaqAnswer>;
  ordinal?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
};

export type LegacyFaqAnswer = {
  __typename?: 'LegacyFaqAnswer';
  json?: Maybe<Scalars['String']>;
};

export type LineItemInput = {
  customer?: InputMaybe<Scalars['String']>;
  modifier_items?: InputMaybe<Array<InputMaybe<ModifierItemInput>>>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  special_instructions?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export enum LineItemStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Ready = 'READY',
  Received = 'RECEIVED'
}

export type LineItemV2 = {
  __typename?: 'LineItemV2';
  brand?: Maybe<BrandV2>;
  build_notes?: Maybe<Array<Maybe<BuildNote>>>;
  customer_name?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  is_completed?: Maybe<Scalars['Boolean']>;
  kitchen_name?: Maybe<Scalars['String']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  menu_item_name_override?: Maybe<Scalars['String']>;
  modifier_items?: Maybe<Array<Maybe<OrderModifierItemV2>>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<OrderV2>;
  ordinal?: Maybe<Scalars['Int']>;
  prep_station_type_id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  special_instructions?: Maybe<Scalars['String']>;
  status?: Maybe<LineItemStatus>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type LocationAccount = {
  __typename?: 'LocationAccount';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<LocationV2>;
};

export type LocationAccountOutput = {
  __typename?: 'LocationAccountOutput';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type LocationPrinterOutput = {
  __typename?: 'LocationPrinterOutput';
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
};

export type LocationSettings = {
  __typename?: 'LocationSettings';
  inserted_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type LocationStat = {
  __typename?: 'LocationStat';
  label?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type LocationStats = {
  __typename?: 'LocationStats';
  id: Scalars['Int'];
  /** Get todays labor for a given location */
  labor_today?: Maybe<LocationStat>;
  percent_on_time_last_20_min?: Maybe<LocationStat>;
  percent_under_20_min_last_20_min?: Maybe<Scalars['Float']>;
  percent_under_20_min_today?: Maybe<Scalars['Float']>;
  percent_under_20_min_yesterday?: Maybe<Scalars['Float']>;
  /** Get week to date quality score for a given location */
  quality_score_this_week?: Maybe<LocationStat>;
};

export type LocationV2 = {
  __typename?: 'LocationV2';
  are_drinks_self_serve?: Maybe<Scalars['Boolean']>;
  busy_mode?: Maybe<Scalars['Boolean']>;
  default_busy_mode_time?: Maybe<Scalars['Int']>;
  default_prep_time?: Maybe<Scalars['Int']>;
  display_hours?: Maybe<Array<Maybe<DisplayHours>>>;
  display_name?: Maybe<Scalars['String']>;
  google_place_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_dine_in_available?: Maybe<Scalars['Boolean']>;
  is_open?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  prep_stations?: Maybe<Array<Maybe<PrepStationV2>>>;
  prep_time?: Maybe<Scalars['Int']>;
  recent_prep_time?: Maybe<Scalars['Int']>;
  settings?: Maybe<LocationSettings>;
  share_link?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  store_menu_category_prep_stations?: Maybe<Array<Maybe<StoreMenuCategoryPrepStationV2>>>;
  store_menu_item_prep_stations?: Maybe<Array<Maybe<StoreMenuItemPrepStationV2>>>;
  store_menu_prep_stations?: Maybe<Array<Maybe<StoreMenuPrepStationV2>>>;
  stores?: Maybe<Array<Maybe<PublicStore>>>;
  street?: Maybe<Scalars['String']>;
  stripe_location_id?: Maybe<Scalars['String']>;
  tax_rate?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  yelp_location_id?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type LogoutEmployeeType = {
  __typename?: 'LogoutEmployeeType';
  success?: Maybe<Scalars['Boolean']>;
};

export type LoyaltyAccount = {
  __typename?: 'LoyaltyAccount';
  credits?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lifetime_points?: Maybe<Scalars['Int']>;
  loyalty_programs?: Maybe<Array<Maybe<LoyaltyProgram>>>;
  pending_credits?: Maybe<Scalars['Int']>;
  pending_points?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
};

export type LoyaltyProgram = {
  __typename?: 'LoyaltyProgram';
  id?: Maybe<Scalars['Int']>;
  loyalty_program_tiers?: Maybe<Array<Maybe<LoyaltyProgramTier>>>;
  name?: Maybe<Scalars['String']>;
};

export type LoyaltyProgramTier = {
  __typename?: 'LoyaltyProgramTier';
  credits?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  points_required?: Maybe<Scalars['Int']>;
};

export type MakeGroupOrderInput = {
  first_name?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  is_group_order?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  spend_limit?: InputMaybe<Scalars['Int']>;
};

export type MandatoryAdvancedScheduling = {
  __typename?: 'MandatoryAdvancedScheduling';
  copy?: Maybe<Scalars['String']>;
  minutes?: Maybe<Scalars['Int']>;
};

export type MarketingBillboard = {
  __typename?: 'MarketingBillboard';
  background_color: Scalars['String'];
  description: Scalars['String'];
  end_time?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Image>;
  locations?: Maybe<Array<Maybe<LocationV2>>>;
  ordinal?: Maybe<Scalars['Int']>;
  platforms?: Maybe<Array<Maybe<PlatformsEnum>>>;
  portrait_image?: Maybe<Image>;
  redirect?: Maybe<MarketingBillboardRedirect>;
  start_time?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  visibility?: Maybe<Array<Maybe<BannerVisibilityEnum>>>;
};

export type MarketingBillboardBrandPageRedirect = {
  __typename?: 'MarketingBillboardBrandPageRedirect';
  brand_slug: Scalars['String'];
  button_label?: Maybe<Scalars['String']>;
  page: Scalars['String'];
};

export type MarketingBillboardBulkUpdateInput = {
  id: Scalars['Int'];
  ordinal: Scalars['Int'];
};

export type MarketingBillboardCustomRedirect = {
  __typename?: 'MarketingBillboardCustomRedirect';
  button_label?: Maybe<Scalars['String']>;
  page: Scalars['String'];
  url: Scalars['String'];
};

export type MarketingBillboardRedirect = MarketingBillboardBrandPageRedirect | MarketingBillboardCustomRedirect;

export type MarketingBillboardRedirectInput = {
  attributes: Scalars['JSON'];
  button_label?: InputMaybe<Scalars['String']>;
  page: Scalars['String'];
};

export type Media = Image | Video;

export type MenuCategoryV2 = {
  __typename?: 'MenuCategoryV2';
  id?: Maybe<Scalars['Int']>;
  menu_items?: Maybe<Array<Maybe<MenuItemV2>>>;
  name?: Maybe<Scalars['String']>;
};

export type MenuEntity = {
  __typename?: 'MenuEntity';
  blurhash?: Maybe<Scalars['String']>;
  brand?: Maybe<MenuEntityBrand>;
  image_url?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<MenuEntityLocation>>>;
  name?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['Int']>;
  target_type?: Maybe<TargetType>;
};

export type MenuEntityBrand = {
  __typename?: 'MenuEntityBrand';
  about?: Maybe<Scalars['String']>;
  cuisine?: Maybe<Scalars['String']>;
  food_hero_image_blurhash?: Maybe<Scalars['String']>;
  food_hero_image_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  logo_blurhash?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  short_cuisine?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
};

export type MenuEntityLocation = {
  __typename?: 'MenuEntityLocation';
  display_to_guests?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  location_slug?: Maybe<Scalars['String']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  blurhash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MenuItemPairing = {
  __typename?: 'MenuItemPairing';
  id?: Maybe<Scalars['Int']>;
  ordinal?: Maybe<Scalars['Int']>;
  pairing_id?: Maybe<Scalars['Int']>;
};

export type MenuItemTag = {
  __typename?: 'MenuItemTag';
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['String']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  ordinal?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type MenuItemTags = {
  __typename?: 'MenuItemTags';
  id?: Maybe<Scalars['Int']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  ordinal?: Maybe<Scalars['Int']>;
};

export type MenuItemV2 = {
  __typename?: 'MenuItemV2';
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_completed?: Maybe<Scalars['Boolean']>;
  is_discrete?: Maybe<Scalars['Boolean']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  modifier_lists?: Maybe<Array<Maybe<ModifierListV2>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  units_remaining?: Maybe<Scalars['Int']>;
};

export type MenuSyncEvent = {
  __typename?: 'MenuSyncEvent';
  brand_id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Int']>;
  synced_by_email?: Maybe<Scalars['String']>;
};

export type MenuV2 = {
  __typename?: 'MenuV2';
  doordash_menu_id?: Maybe<Scalars['String']>;
  grubhub_menu_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  menu_categories?: Maybe<Array<Maybe<MenuCategoryV2>>>;
  toast_menu_id?: Maybe<Scalars['String']>;
  ubereats_menu_id?: Maybe<Scalars['String']>;
};

export type ModifierItemInput = {
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ModifierItemV2 = {
  __typename?: 'ModifierItemV2';
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_discrete?: Maybe<Scalars['Boolean']>;
  modifier_item_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  units_remaining?: Maybe<Scalars['Int']>;
};

export type ModifierListV2 = {
  __typename?: 'ModifierListV2';
  id?: Maybe<Scalars['Int']>;
  modifier_items?: Maybe<Array<Maybe<ModifierItemV2>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  /**
   * Accepts order and triggers job to accept in provider.
   * Turns order state to cooking.
   */
  accept_order?: Maybe<OrderV2>;
  /**
   * Accepts order and triggers job to eventually accept in provider.
   * Turns order state to scheduled.
   */
  accept_scheduled_order?: Maybe<OrderV2>;
  acknowledge_notification?: Maybe<Scalars['Boolean']>;
  /** Activates menu item in our DB and triggers jobs to activate in providers */
  activate_menu_item?: Maybe<MenuItemV2>;
  /** Activates modifier item in our DB and triggers jobs to activate in providers */
  activate_modifier_item?: Maybe<ModifierItemV2>;
  /** Activates the store to go live */
  activate_store?: Maybe<Scalars['Int']>;
  /** Set store active and trigger updated status in provider APIs */
  activate_stores?: Maybe<Array<Maybe<StoreV2>>>;
  /** Update customer loyalty account credits */
  add_credits_to_loyalty_account?: Maybe<LoyaltyAccount>;
  /** Add an item to an existing cart */
  add_item?: Maybe<Cart>;
  /** DEPRECATED - USE THE QUERY (ABOVE) NOT THE MUTATION */
  address_delivery_estimates?: Maybe<Array<Maybe<DeliveryEstimate>>>;
  adjust_catering_subtotal?: Maybe<Scalars['Int']>;
  apply_kiosk_fundraiser_code?: Maybe<Cart>;
  /** Promos for logged in users with valid promo code */
  apply_promo_code?: Maybe<Cart>;
  /** Send receipt email after checkout */
  attach_receipt_email?: Maybe<AttachReceiptEmailPayload>;
  /** Send receipt email after checkout */
  attach_receipt_email_to_cart?: Maybe<AttachReceiptEmailPayload>;
  authenticate_employee?: Maybe<Employee>;
  bulk_update_marketing_billboards?: Maybe<Scalars['Boolean']>;
  /** Bulk Update Pairings */
  bulk_update_pairings?: Maybe<Scalars['Int']>;
  /** Bulk updates store hours for all store ids */
  bulk_update_store_hours?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Updates existing stores */
  bulk_update_stores?: Maybe<Scalars['Int']>;
  /** Bulk Update Tag */
  bulk_update_tags?: Maybe<Scalars['Int']>;
  cancel_order?: Maybe<Scalars['Int']>;
  /** Manually capture payment intent from kiosk */
  capture_payment_intent?: Maybe<StripePaymentIntent>;
  /** Get an existing or a new empty one */
  cart?: Maybe<Cart>;
  /** Clone a cart */
  clone_cart?: Maybe<Cart>;
  /** Adds a Brand */
  create_brand?: Maybe<OperationsBrand>;
  /** Create a cart */
  create_cart?: Maybe<Cart>;
  /** Create Catering Order */
  create_catering_order: OperationsCateringOrder;
  create_coming_soon?: Maybe<ComingSoon>;
  /** Authenticate terminal */
  create_connection_token?: Maybe<StripeConnectionToken>;
  create_coupon?: Maybe<Coupon>;
  /** Create Custom Hours */
  create_custom_hours?: Maybe<Scalars['Int']>;
  /** Create Employee */
  create_employee?: Maybe<Employee>;
  /** Create stripe in-person payment intent (guest-kiosk) */
  create_guest_kiosk_payment_intent?: Maybe<StripePaymentIntent>;
  /** Create stripe in-person payment intent (kiosk) */
  create_in_person_payment_intent?: Maybe<StripePaymentIntent>;
  /** Create Issue */
  create_issue?: Maybe<StoreOrderIssue>;
  /** Create Location */
  create_location?: Maybe<Scalars['String']>;
  /** Create location_account */
  create_location_account?: Maybe<Scalars['Int']>;
  /** Create Marketing Billboard */
  create_marketing_billboard?: Maybe<MarketingBillboard>;
  /** Create payment method, e.g. Apple Pay */
  create_non_card_payment_method?: Maybe<StoredPaymentMethod>;
  /** Create stripe online payment intent */
  create_online_payment_intent?: Maybe<StripePaymentIntent>;
  /** Create or update delivery address */
  create_or_update_delivery_address?: Maybe<DeliveryAddress>;
  /** Create or update menu item build notes */
  create_or_update_menu_item_build_notes?: Maybe<BuildNotesOutput>;
  create_or_update_menu_item_prep_station_type?: Maybe<Scalars['Int']>;
  /** Create stripe payment method and associate with customer */
  create_payment_method?: Maybe<StoredPaymentMethod>;
  create_prep_station?: Maybe<PrepStationV2>;
  /** Create Printer for Location */
  create_printer?: Maybe<LocationPrinterOutput>;
  /** Creates a new store */
  create_store?: Maybe<Scalars['Int']>;
  create_store_menu_category_prep_station?: Maybe<StoreMenuCategoryPrepStationV2>;
  create_store_menu_item_prep_station?: Maybe<StoreMenuItemPrepStationV2>;
  create_store_menu_prep_station?: Maybe<StoreMenuPrepStationV2>;
  /** Each terminal has to be associated with a Stripe location */
  create_stripe_location?: Maybe<StripeLocation>;
  /** Create a new tag */
  create_tag: OperationsTag;
  /** Deactivates menu item in our DB and triggers jobs to deactivate in providers */
  deactivate_menu_item?: Maybe<MenuItemV2>;
  /** Deactivates modifier item in our DB and triggers jobs to activate in providers */
  deactivate_modifier_item?: Maybe<ModifierItemV2>;
  /** Set store inactive and trigger updated status in provider APIs */
  deactivate_stores?: Maybe<Array<Maybe<StoreV2>>>;
  /** Decreases the bag count of the order */
  decrease_bag_count?: Maybe<OrderV2>;
  /** Delete own account */
  delete_account?: Maybe<DeleteAccountOutput>;
  /** Deletes a Brand */
  delete_brand?: Maybe<Scalars['Boolean']>;
  /** Delete Catering Order */
  delete_catering_order?: Maybe<Scalars['Boolean']>;
  delete_coupon?: Maybe<Coupon>;
  /** Create Custom Hours */
  delete_custom_hours?: Maybe<Scalars['Int']>;
  /** Delete delivery address */
  delete_delivery_address?: Maybe<DeliveryAddress>;
  /** Delete Employee */
  delete_employee?: Maybe<Scalars['Boolean']>;
  delete_guest_account?: Maybe<DeleteGuestAccountOutput>;
  delete_marketing_billboard?: Maybe<Scalars['Boolean']>;
  /** Delete menu item build notes */
  delete_menu_item_build_notes?: Maybe<BuildNotesOutput>;
  /** Remove a payment method */
  delete_payment_method?: Maybe<StoredPaymentMethod>;
  delete_prep_station?: Maybe<PrepStationV2>;
  /** Delete Printer */
  delete_printer?: Maybe<LocationPrinterOutput>;
  delete_store_menu_category_prep_station?: Maybe<StoreMenuCategoryPrepStationV2>;
  delete_store_menu_item_prep_station?: Maybe<StoreMenuItemPrepStationV2>;
  delete_store_menu_prep_station?: Maybe<StoreMenuPrepStationV2>;
  /** Get delivery estimates for a cart with a delivery address attached */
  delivery_estimate?: Maybe<DeliveryEstimate>;
  /** Get delivery estimates for a cart and list of locations */
  delivery_estimates?: Maybe<Array<Maybe<DeliveryEstimate>>>;
  /** Fulfillment is triggered from frontend since cart is stored in memory */
  fulfill_in_person_order?: Maybe<FulfillInPersonOrderPayload>;
  generate_line_item_chits: Array<GeneratedChit>;
  generate_line_item_custom_chits?: Maybe<GeneratedChit>;
  /** Increases the bag count of the order */
  increase_bag_count?: Maybe<OrderV2>;
  /** Joins a group order cart */
  join_group_order: Participant;
  logout_employee?: Maybe<LogoutEmployeeType>;
  /** Update is_group_order for an existing cart and create the creator participant */
  make_cart_group_order?: Maybe<Cart>;
  mark_chit_printed?: Maybe<GeneratedChit>;
  /** Marks order as complete, moves it off of status board */
  mark_complete?: Maybe<OrderV2>;
  mark_custom_chit_tickets_ready?: Maybe<CustomChitTicket>;
  mark_custom_chit_tickets_received?: Maybe<CustomChitTicket>;
  mark_custom_chit_tickets_unready?: Maybe<CustomChitTicket>;
  /** Marks order as not complete, will move back to status board */
  mark_incomplete?: Maybe<OrderV2>;
  mark_items_as_custom_chit?: Maybe<CustomChitOutput>;
  mark_line_item_scanned?: Maybe<ScannedLineItem>;
  mark_line_item_tickets_ready?: Maybe<Array<Maybe<TicketV2>>>;
  mark_line_item_tickets_received?: Maybe<Array<Maybe<TicketV2>>>;
  mark_line_item_tickets_unready?: Maybe<Array<Maybe<TicketV2>>>;
  move_cooking_order_back_into_scheduled?: Maybe<Scalars['Boolean']>;
  /** Manually move order in queue into cooking (customer arrives early) */
  move_into_cooking?: Maybe<OrderV2>;
  move_scheduled_order_into_cooking?: Maybe<Scalars['Boolean']>;
  /** Notify oversold item */
  notify_oversold_item?: Maybe<Scalars['Int']>;
  /** Permanently Deactivates store in LK */
  permanently_deactivate_store?: Maybe<Scalars['Int']>;
  /**
   * Prints receipt with a pre-formatted order input from frontend.
   * Used to print all receipts from App but will be deprecated in future to do
   * formatting in the backend instead.
   */
  printReceipt?: Maybe<PrintReceiptPayload>;
  /** Prints extra order receipts */
  print_receipt?: Maybe<OrderV2>;
  purchase_reward?: Maybe<CustomerReward>;
  /** Recycle a previous kiosk order as a TRAINING order */
  recycle_order?: Maybe<OrderV2>;
  /** Register reader from list of available readers in network */
  register_reader?: Maybe<StripeReader>;
  /** Remove item from an existing cart */
  remove_item?: Maybe<Cart>;
  remove_kiosk_fundraiser_code?: Maybe<Cart>;
  /** Remove promo on current cart */
  remove_promo_code?: Maybe<Cart>;
  remove_reward?: Maybe<Scalars['Boolean']>;
  /** Endpoint to add an event to resolve oversold item */
  resolve_oversold?: Maybe<Scalars['Boolean']>;
  /** Deactivates modifier item in our DB and triggers jobs to activate in providers */
  restock_store_menu_item?: Maybe<ModifierItemV2>;
  /** Deactivates modifier item in our DB and triggers jobs to activate in providers */
  restock_store_modifier_item?: Maybe<ModifierItemV2>;
  /** Re upload images from Tags, Brands and Menu Items */
  resync_s3_images?: Maybe<Scalars['Boolean']>;
  set_customer_in_cart?: Maybe<Cart>;
  set_location_featured_items: Array<PublicMenuItem>;
  /** Sets units remaining for store menu items */
  set_remaining_store_menu_item?: Maybe<ModifierItemV2>;
  /** Deactivates modifier item in our DB and triggers jobs to activate in providers */
  set_remaining_store_modifier_item?: Maybe<ModifierItemV2>;
  /** Submit a survey question response */
  submit_sent_survey_question_response?: Maybe<SentSurvey>;
  /** Syncs revenue centers from Toast */
  sync_revenue_centers?: Maybe<Array<Maybe<SyncRevenueCentersOutput>>>;
  /** Updates a Brand */
  update_brand?: Maybe<OperationsBrand>;
  /** Update name, location, phone_number, order_time for an existing cart */
  update_cart?: Maybe<Cart>;
  /** Update Catering Order */
  update_catering_order: OperationsCateringOrder;
  update_coupon?: Maybe<Coupon>;
  update_customer?: Maybe<Customer>;
  /** Update delivery address */
  update_delivery_address?: Maybe<DeliveryAddress>;
  /** Update Employee */
  update_employee?: Maybe<Employee>;
  /** Update Food Issue */
  update_issue?: Maybe<StoreOrderIssue>;
  /** Update item in an existing cart */
  update_item?: Maybe<Cart>;
  update_kiosk_cart?: Maybe<Cart>;
  /** Update kitchen names */
  update_kitchen_names?: Maybe<Array<Maybe<OperationsItem>>>;
  /** Update location */
  update_location?: Maybe<Scalars['String']>;
  /** Update location_account */
  update_location_account?: Maybe<Scalars['Int']>;
  /** Update Marketing Billboard */
  update_marketing_billboard?: Maybe<MarketingBillboard>;
  /** Update Menu Item Discrete Status */
  update_menu_item_discrete_status?: Maybe<Scalars['Boolean']>;
  /** Update Modifier Item Discrete Status */
  update_modifier_item_discrete_status?: Maybe<Scalars['Boolean']>;
  /** Update a payment method */
  update_payment_method?: Maybe<StoredPaymentMethod>;
  /** Move from ASAP to Scheduled and vice versa */
  update_pickup_time?: Maybe<UpdatePickupTimeOutput>;
  update_prep_station?: Maybe<PrepStationV2>;
  /** Update Printer */
  update_printer?: Maybe<LocationPrinterOutput>;
  /** Updates an existing store */
  update_store?: Maybe<Scalars['Int']>;
  /** Updates store credentials */
  update_store_credentials?: Maybe<Scalars['Int']>;
  /** Update a tag */
  update_tag: OperationsTag;
  update_ticket_line_item_status?: Maybe<KdsTicketLineItem>;
  update_ticket_order_status?: Maybe<KdsTicket>;
  update_ticket_sub_line_item_status?: Maybe<KdsTicketSubLineItem>;
  upload_media?: Maybe<Media>;
  /** Upload build grid image on Menu Items */
  upload_menu_item_build_grid_image?: Maybe<OperationsMenuItem>;
  /** Upload final plated image on Menu Items */
  upload_menu_item_final_plated_image?: Maybe<OperationsMenuItem>;
  /** Upload image on Menu Items */
  upload_menu_item_image?: Maybe<OperationsMenuItem>;
  /** Create or update cart client metadata */
  upsert_raw_cart_client_metadata?: Maybe<Scalars['String']>;
  /** Validate delivery address and time are deliverable */
  validate_delivery?: Maybe<DeliveryValidation>;
};


export type MutationAccept_OrderArgs = {
  formatted_prep_time?: InputMaybe<Scalars['String']>;
  location_slug?: InputMaybe<Scalars['String']>;
  prep_time?: InputMaybe<Scalars['Int']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationAccept_Scheduled_OrderArgs = {
  formatted_prep_time?: InputMaybe<Scalars['String']>;
  location_slug?: InputMaybe<Scalars['String']>;
  prep_time?: InputMaybe<Scalars['Int']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationAcknowledge_NotificationArgs = {
  notification_id?: InputMaybe<Scalars['Int']>;
};


export type MutationActivate_Menu_ItemArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationActivate_Modifier_ItemArgs = {
  modifier_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationActivate_StoreArgs = {
  store_id?: InputMaybe<Scalars['Int']>;
};


export type MutationActivate_StoresArgs = {
  provider?: InputMaybe<Scalars['String']>;
  store_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationAdd_Credits_To_Loyalty_AccountArgs = {
  input?: InputMaybe<AddCreditsToLoyaltyAccountInput>;
};


export type MutationAdd_ItemArgs = {
  input?: InputMaybe<AddItemInput>;
};


export type MutationAddress_Delivery_EstimatesArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  delivery_address?: InputMaybe<DeliveryAddressInput>;
  location_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationAdjust_Catering_SubtotalArgs = {
  adjusted_subtotal?: InputMaybe<Scalars['Int']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationApply_Kiosk_Fundraiser_CodeArgs = {
  cart_id: Scalars['String'];
  code: Scalars['String'];
};


export type MutationApply_Promo_CodeArgs = {
  apply_promo_code_input?: InputMaybe<ApplyPromoCodeInput>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
};


export type MutationAttach_Receipt_EmailArgs = {
  payment_intent_id?: InputMaybe<Scalars['String']>;
  receipt_email?: InputMaybe<Scalars['String']>;
};


export type MutationAttach_Receipt_Email_To_CartArgs = {
  external_id?: InputMaybe<Scalars['String']>;
  receipt_email?: InputMaybe<Scalars['String']>;
};


export type MutationAuthenticate_EmployeeArgs = {
  device?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  pin_code?: InputMaybe<Scalars['String']>;
  session_roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationBulk_Update_Marketing_BillboardsArgs = {
  marketing_billboards: Array<MarketingBillboardBulkUpdateInput>;
};


export type MutationBulk_Update_PairingsArgs = {
  menuItemId: Scalars['Int'];
  pairingIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationBulk_Update_Store_HoursArgs = {
  store_hours?: InputMaybe<Array<InputMaybe<GenericStoreHoursInput>>>;
  store_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationBulk_Update_StoresArgs = {
  stores?: InputMaybe<Array<InputMaybe<StoreRecordInput>>>;
};


export type MutationBulk_Update_TagsArgs = {
  tags?: InputMaybe<Array<InputMaybe<TagBulkUpdateInput>>>;
};


export type MutationCancel_OrderArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationCapture_Payment_IntentArgs = {
  payment_intent_id?: InputMaybe<Scalars['String']>;
};


export type MutationCartArgs = {
  force_new?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  location_slug?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<GuestOrderType>;
  pickup_type?: InputMaybe<PickupType>;
};


export type MutationClone_CartArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_BrandArgs = {
  input: BrandCreateInput;
};


export type MutationCreate_CartArgs = {
  cart?: InputMaybe<CreateCartInput>;
};


export type MutationCreate_Catering_OrderArgs = {
  input?: InputMaybe<CreateCateringOrderInput>;
};


export type MutationCreate_Coming_SoonArgs = {
  create_coming_soon_input?: InputMaybe<ComingSoonInput>;
};


export type MutationCreate_Connection_TokenArgs = {
  location_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_CouponArgs = {
  create_coupon_input?: InputMaybe<CreateCouponInput>;
};


export type MutationCreate_Custom_HoursArgs = {
  input?: InputMaybe<CustomHoursInput>;
};


export type MutationCreate_EmployeeArgs = {
  employee_input?: InputMaybe<EmployeeInput>;
};


export type MutationCreate_Guest_Kiosk_Payment_IntentArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  stripe_payment_intent_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_In_Person_Payment_IntentArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  cart_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  stripe_payment_intent_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_IssueArgs = {
  input?: InputMaybe<StoreOrderIssueInput>;
};


export type MutationCreate_LocationArgs = {
  input?: InputMaybe<AddLocationInput>;
};


export type MutationCreate_Location_AccountArgs = {
  email?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreate_Marketing_BillboardArgs = {
  input: CreateMarketingBillboardInput;
};


export type MutationCreate_Non_Card_Payment_MethodArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  payment_type?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_Online_Payment_IntentArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_Or_Update_Delivery_AddressArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<DeliveryAddressInput>;
};


export type MutationCreate_Or_Update_Menu_Item_Build_NotesArgs = {
  input?: InputMaybe<BuildNotesInput>;
};


export type MutationCreate_Or_Update_Menu_Item_Prep_Station_TypeArgs = {
  id?: InputMaybe<Scalars['Int']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
  menu_item_name_override?: InputMaybe<Scalars['String']>;
  prep_station_type_id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreate_Payment_MethodArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  stripe_payment_method_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_Prep_StationArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  prep_station_type_id?: InputMaybe<Scalars['Int']>;
  printer_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_PrinterArgs = {
  external_id?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreate_StoreArgs = {
  brand_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
  start_timestamp?: InputMaybe<Scalars['String']>;
  toast_menu_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_Store_Menu_Category_Prep_StationArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
  store_menu_category_id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreate_Store_Menu_Item_Prep_StationArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
  store_menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreate_Store_Menu_Prep_StationArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
  store_menu_id?: InputMaybe<Scalars['Int']>;
};


export type MutationCreate_Stripe_LocationArgs = {
  address?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
};


export type MutationCreate_TagArgs = {
  menu_item_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tag: CreateTagInput;
};


export type MutationDeactivate_Menu_ItemArgs = {
  deactivation_metadata?: InputMaybe<Scalars['JSON']>;
  location_id?: InputMaybe<Scalars['Int']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeactivate_Modifier_ItemArgs = {
  deactivation_metadata?: InputMaybe<Scalars['JSON']>;
  modifier_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeactivate_StoresArgs = {
  provider?: InputMaybe<Scalars['String']>;
  store_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationDecrease_Bag_CountArgs = {
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelete_AccountArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<DeleteAccountInput>;
};


export type MutationDelete_BrandArgs = {
  id: Scalars['Int'];
};


export type MutationDelete_Catering_OrderArgs = {
  id: Scalars['Int'];
};


export type MutationDelete_CouponArgs = {
  delete_coupon_input?: InputMaybe<DeleteCouponInput>;
};


export type MutationDelete_Custom_HoursArgs = {
  custom_hours_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationDelete_Delivery_AddressArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type MutationDelete_EmployeeArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelete_Guest_AccountArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<DeleteGuestAccountInput>;
};


export type MutationDelete_Marketing_BillboardArgs = {
  id: Scalars['Int'];
};


export type MutationDelete_Menu_Item_Build_NotesArgs = {
  custom_chit_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelete_Payment_MethodArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelete_Prep_StationArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelete_PrinterArgs = {
  printer_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelete_Store_Menu_Category_Prep_StationArgs = {
  store_menu_category_prep_station_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelete_Store_Menu_Item_Prep_StationArgs = {
  store_menu_item_prep_station_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelete_Store_Menu_Prep_StationArgs = {
  store_menu_prep_station_id?: InputMaybe<Scalars['Int']>;
};


export type MutationDelivery_EstimateArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
};


export type MutationDelivery_EstimatesArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
  location_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationFulfill_In_Person_OrderArgs = {
  cart?: InputMaybe<Scalars['JSON']>;
};


export type MutationGenerate_Line_Item_ChitsArgs = {
  max_quantity: Scalars['Int'];
  prep_station_id: Scalars['Int'];
  store_order_id: Scalars['Int'];
  store_order_line_item_id: Scalars['Int'];
  store_order_line_item_prep_station_ticket_ids: Array<Scalars['Int']>;
};


export type MutationGenerate_Line_Item_Custom_ChitsArgs = {
  custom_chit_prep_station_ticket_id: Scalars['Int'];
  max_quantity: Scalars['Int'];
  prep_station_id: Scalars['Int'];
  store_order_id: Scalars['Int'];
  store_order_line_item_id: Scalars['Int'];
};


export type MutationIncrease_Bag_CountArgs = {
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationJoin_Group_OrderArgs = {
  cart_id: Scalars['String'];
  input: JoinGroupOrderInput;
};


export type MutationLogout_EmployeeArgs = {
  device?: InputMaybe<Scalars['String']>;
  employee_id?: InputMaybe<Scalars['Int']>;
  employee_login_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};


export type MutationMake_Cart_Group_OrderArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<MakeGroupOrderInput>;
};


export type MutationMark_Chit_PrintedArgs = {
  chit_id: Scalars['Int'];
  prep_station_id: Scalars['Int'];
};


export type MutationMark_CompleteArgs = {
  completed_by?: InputMaybe<Scalars['String']>;
  current_employee_id?: InputMaybe<Scalars['Int']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationMark_Custom_Chit_Tickets_ReadyArgs = {
  input?: InputMaybe<CustomChitTicketReadyInput>;
};


export type MutationMark_Custom_Chit_Tickets_ReceivedArgs = {
  input?: InputMaybe<CustomChitTicketInput>;
};


export type MutationMark_Custom_Chit_Tickets_UnreadyArgs = {
  input?: InputMaybe<CustomChitTicketInput>;
};


export type MutationMark_IncompleteArgs = {
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationMark_Items_As_Custom_ChitArgs = {
  modifier_item?: InputMaybe<CustomChitInput>;
};


export type MutationMark_Line_Item_ScannedArgs = {
  store_order_line_item_id: Scalars['Int'];
};


export type MutationMark_Line_Item_Tickets_ReadyArgs = {
  current_cook_id?: InputMaybe<Scalars['Int']>;
  prep_station_id?: InputMaybe<Scalars['Int']>;
  print_chits?: InputMaybe<Scalars['Boolean']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_prep_station_ticket_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationMark_Line_Item_Tickets_ReceivedArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_prep_station_ticket_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationMark_Line_Item_Tickets_UnreadyArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_prep_station_ticket_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type MutationMove_Cooking_Order_Back_Into_ScheduledArgs = {
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationMove_Into_CookingArgs = {
  location_slug?: InputMaybe<Scalars['String']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationMove_Scheduled_Order_Into_CookingArgs = {
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationNotify_Oversold_ItemArgs = {
  input?: InputMaybe<OversoldItemInput>;
};


export type MutationPermanently_Deactivate_StoreArgs = {
  store_id?: InputMaybe<Scalars['Int']>;
};


export type MutationPrintReceiptArgs = {
  location?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderInput>;
};


export type MutationPrint_ReceiptArgs = {
  location?: InputMaybe<Scalars['String']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationPurchase_RewardArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  reward_id?: InputMaybe<Scalars['Int']>;
};


export type MutationRecycle_OrderArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
};


export type MutationRegister_ReaderArgs = {
  label?: InputMaybe<Scalars['String']>;
  registration_code?: InputMaybe<Scalars['String']>;
};


export type MutationRemove_ItemArgs = {
  input?: InputMaybe<RemoveItemInput>;
};


export type MutationRemove_Kiosk_Fundraiser_CodeArgs = {
  cart_id: Scalars['String'];
};


export type MutationRemove_Promo_CodeArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  cart_id?: InputMaybe<Scalars['String']>;
};


export type MutationRemove_RewardArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  reward_id?: InputMaybe<Scalars['Int']>;
};


export type MutationResolve_OversoldArgs = {
  notes?: InputMaybe<Scalars['String']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type MutationRestock_Store_Menu_ItemArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
  store_menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationRestock_Store_Modifier_ItemArgs = {
  store_modifier_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationSet_Customer_In_CartArgs = {
  cart_id: Scalars['String'];
  customer_id?: InputMaybe<Scalars['Int']>;
};


export type MutationSet_Location_Featured_ItemsArgs = {
  location_id: Scalars['Int'];
  store_menu_item_ids: Array<Scalars['Int']>;
};


export type MutationSet_Remaining_Store_Menu_ItemArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
  store_menu_item_id?: InputMaybe<Scalars['Int']>;
  units_remaining?: InputMaybe<Scalars['Int']>;
};


export type MutationSet_Remaining_Store_Modifier_ItemArgs = {
  store_modifier_item_id?: InputMaybe<Scalars['Int']>;
  units_remaining?: InputMaybe<Scalars['Int']>;
};


export type MutationSubmit_Sent_Survey_Question_ResponseArgs = {
  input?: InputMaybe<SubmitSentSurveyQuestionResponse>;
};


export type MutationSync_Revenue_CentersArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdate_BrandArgs = {
  id: Scalars['Int'];
  input: BrandUpdateInput;
};


export type MutationUpdate_CartArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<UpdateCartInput>;
};


export type MutationUpdate_Catering_OrderArgs = {
  id: Scalars['Int'];
  input?: InputMaybe<UpdateCateringOrderInput>;
};


export type MutationUpdate_CouponArgs = {
  update_coupon_input?: InputMaybe<UpdateCouponInput>;
};


export type MutationUpdate_CustomerArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  update_customer_input?: InputMaybe<UpdateCustomerInput>;
};


export type MutationUpdate_Delivery_AddressArgs = {
  input?: InputMaybe<DeliveryAddressInput>;
};


export type MutationUpdate_EmployeeArgs = {
  employee_input?: InputMaybe<EmployeeInput>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdate_IssueArgs = {
  id?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<StoreOrderIssueInput>;
};


export type MutationUpdate_ItemArgs = {
  input?: InputMaybe<UpdateItemInput>;
};


export type MutationUpdate_Kiosk_CartArgs = {
  input: UpdateKioskCartInput;
};


export type MutationUpdate_Kitchen_NamesArgs = {
  input?: InputMaybe<KichenNamesInput>;
};


export type MutationUpdate_LocationArgs = {
  input?: InputMaybe<UpdateLocationInput>;
};


export type MutationUpdate_Location_AccountArgs = {
  email?: InputMaybe<Scalars['String']>;
  location_account_id?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdate_Marketing_BillboardArgs = {
  id: Scalars['Int'];
  input: UpdateMarketingBillboardInput;
};


export type MutationUpdate_Menu_Item_Discrete_StatusArgs = {
  is_discrete?: InputMaybe<Scalars['Boolean']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdate_Modifier_Item_Discrete_StatusArgs = {
  is_discrete?: InputMaybe<Scalars['Boolean']>;
  modifier_item_id?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdate_Payment_MethodArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdate_Pickup_TimeArgs = {
  input?: InputMaybe<UpdatePickupTimeInput>;
};


export type MutationUpdate_Prep_StationArgs = {
  name?: InputMaybe<Scalars['String']>;
  prep_station_id?: InputMaybe<Scalars['Int']>;
  prep_station_type_id?: InputMaybe<Scalars['Int']>;
  printer_id?: InputMaybe<Scalars['String']>;
};


export type MutationUpdate_PrinterArgs = {
  external_id?: InputMaybe<Scalars['String']>;
  printer_id?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdate_StoreArgs = {
  brand_id?: InputMaybe<Scalars['Int']>;
  doordash_menu_id?: InputMaybe<Scalars['String']>;
  doordash_store_id?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  start_timestamp?: InputMaybe<Scalars['String']>;
  store_id?: InputMaybe<Scalars['Int']>;
  toast_menu_id?: InputMaybe<Scalars['String']>;
  ubereats_store_id?: InputMaybe<Scalars['String']>;
};


export type MutationUpdate_Store_CredentialsArgs = {
  store_id?: InputMaybe<Scalars['Int']>;
  ubereats_credentials?: InputMaybe<ThirdPartyCredentialsInput>;
};


export type MutationUpdate_TagArgs = {
  menu_item_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tag: UpdateTagInput;
};


export type MutationUpdate_Ticket_Line_Item_StatusArgs = {
  cook_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  prep_station_id?: InputMaybe<Scalars['Int']>;
  print_chit?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Scalars['Int']>;
  target_type?: InputMaybe<Scalars['String']>;
};


export type MutationUpdate_Ticket_Order_StatusArgs = {
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};


export type MutationUpdate_Ticket_Sub_Line_Item_StatusArgs = {
  id?: InputMaybe<Scalars['Int']>;
  prep_station_id?: InputMaybe<Scalars['Int']>;
  print_chit?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};


export type MutationUpload_MediaArgs = {
  input: UploadMediaInput;
};


export type MutationUpload_Menu_Item_Build_Grid_ImageArgs = {
  input: UploadMenuItemImageInput;
  menuItemId: Scalars['Int'];
};


export type MutationUpload_Menu_Item_Final_Plated_ImageArgs = {
  input: UploadMenuItemImageInput;
  menuItemId: Scalars['Int'];
};


export type MutationUpload_Menu_Item_ImageArgs = {
  input: UploadMenuItemImageInput;
  menuItemId: Scalars['Int'];
};


export type MutationUpsert_Raw_Cart_Client_MetadataArgs = {
  browser_checkout_url?: InputMaybe<Scalars['String']>;
  browser_facebook_click_id_cookie?: InputMaybe<Scalars['String']>;
  browser_facebook_pixel_cookie?: InputMaybe<Scalars['String']>;
  browser_segment_anonymous_id?: InputMaybe<Scalars['String']>;
  browser_segment_user_id?: InputMaybe<Scalars['String']>;
  browser_user_agent?: InputMaybe<Scalars['String']>;
  raw_cart_id?: InputMaybe<Scalars['String']>;
};


export type MutationValidate_DeliveryArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
};

export type NewStoreHoursInput = {
  day_of_week?: InputMaybe<Scalars['Int']>;
  hours?: InputMaybe<Array<InputMaybe<ServiceHoursInput>>>;
  id?: InputMaybe<Scalars['Int']>;
};

export type Notification = {
  __typename?: 'Notification';
  data?: Maybe<Scalars['JSON']>;
  expires_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_acknowledged?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  order?: Maybe<NotificationOrder>;
  send_at?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['Int']>;
  target_type?: Maybe<Scalars['String']>;
};

export type NotificationOrder = {
  __typename?: 'NotificationOrder';
  pickup_time?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  store_order_id?: Maybe<Scalars['Int']>;
};

export type OperationsBrand = {
  __typename?: 'OperationsBrand';
  about?: Maybe<Scalars['String']>;
  about_media: Array<Media>;
  cuisine?: Maybe<Scalars['String']>;
  feature_body?: Maybe<Scalars['String']>;
  feature_header?: Maybe<Scalars['String']>;
  feature_image?: Maybe<Image>;
  food_hero_image_blurhash?: Maybe<Scalars['String']>;
  food_hero_image_url?: Maybe<Scalars['String']>;
  gallery_media: Array<Media>;
  id: Scalars['Int'];
  is_active?: Maybe<Scalars['Boolean']>;
  logo_blurhash?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  menu_items?: Maybe<Array<Maybe<OperationsMenuItem>>>;
  modifier_items?: Maybe<Array<Maybe<OperationsModifierItem>>>;
  name: Scalars['String'];
  short_cuisine?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  stores?: Maybe<Array<Maybe<OperationsStore>>>;
  tagline?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
  typ?: Maybe<BrandType>;
  url?: Maybe<Scalars['String']>;
};

export type OperationsBrandListItem = {
  __typename?: 'OperationsBrandListItem';
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type OperationsBrandsInput = {
  brand_id?: InputMaybe<Scalars['Int']>;
  brand_slug?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
};

export type OperationsCateringOrder = {
  __typename?: 'OperationsCateringOrder';
  customer_delivery_fee?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  external_id: Scalars['String'];
  id: Scalars['String'];
  inserted_at: Scalars['String'];
  invoice_id?: Maybe<Scalars['String']>;
  invoice_paid_at?: Maybe<Scalars['String']>;
  service_fee?: Maybe<Scalars['Int']>;
  service_fee_rate?: Maybe<Scalars['Int']>;
  subtotal?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  tax_rate?: Maybe<Scalars['Int']>;
  tip?: Maybe<Scalars['Int']>;
  tip_rate?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type OperationsCustomer = {
  __typename?: 'OperationsCustomer';
  auth0_user_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['Float']>;
  is_phone_number_verified?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  loyalty_account?: Maybe<LoyaltyAccount>;
  phone_number?: Maybe<Scalars['String']>;
};

export type OperationsItem = OperationsMenuItem | OperationsModifierItem;

export type OperationsItemInput = {
  id?: InputMaybe<Scalars['Int']>;
  item_type?: InputMaybe<Scalars['String']>;
  kitchen_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  prep_type?: InputMaybe<PrepTypeEnum>;
};

export type OperationsMenu = {
  __typename?: 'OperationsMenu';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type OperationsMenuItem = {
  __typename?: 'OperationsMenuItem';
  blurhash?: Maybe<Scalars['String']>;
  build_grid_img_url?: Maybe<Scalars['String']>;
  build_notes?: Maybe<Array<Maybe<BuildNote>>>;
  external_id?: Maybe<Scalars['String']>;
  final_plated_img_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  is_discrete?: Maybe<Scalars['Boolean']>;
  item_type?: Maybe<Scalars['String']>;
  kitchen_name?: Maybe<Scalars['String']>;
  menu_item_name_override?: Maybe<Scalars['String']>;
  menu_item_prep_station_type_id?: Maybe<Scalars['Int']>;
  modifier_lists?: Maybe<Array<Maybe<OperationsModifierList>>>;
  name?: Maybe<Scalars['String']>;
  prep_type?: Maybe<PrepTypeEnum>;
  store_menu_items?: Maybe<Array<Maybe<StoreMenuItem>>>;
  tags?: Maybe<Array<Maybe<OperationsTag>>>;
};

export type OperationsModifierItem = {
  __typename?: 'OperationsModifierItem';
  custom_chit?: Maybe<CustomChit>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_discrete?: Maybe<Scalars['Boolean']>;
  item_type?: Maybe<Scalars['String']>;
  kitchen_name?: Maybe<Scalars['String']>;
  modifier_list_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  modifier_list_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  prep_type?: Maybe<PrepTypeEnum>;
};

export type OperationsModifierList = {
  __typename?: 'OperationsModifierList';
  id?: Maybe<Scalars['Int']>;
  modifier_items?: Maybe<Array<Maybe<OperationsModifierItem>>>;
  name?: Maybe<Scalars['String']>;
  ordinal?: Maybe<Scalars['Int']>;
};

export type OperationsStore = {
  __typename?: 'OperationsStore';
  brand_id?: Maybe<Scalars['Int']>;
  doordash_store_id?: Maybe<Scalars['String']>;
  grubhub_store_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_open?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationV2>;
  ordinal?: Maybe<Scalars['Int']>;
  revel_store_id?: Maybe<Scalars['String']>;
  tax_rate?: Maybe<Scalars['Int']>;
  ubereats_store_id?: Maybe<Scalars['String']>;
};

export type OperationsStoreMenuItem = {
  __typename?: 'OperationsStoreMenuItem';
  direct_price?: Maybe<Scalars['Int']>;
  display_to_guests?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  item_blurhash?: Maybe<Scalars['String']>;
  item_description?: Maybe<Scalars['String']>;
  item_image_url?: Maybe<Scalars['String']>;
  item_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Int']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  ordinal?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  store_menu_category_id?: Maybe<Scalars['Int']>;
  tax_rate?: Maybe<Scalars['Int']>;
  toast_menu_item_id?: Maybe<Scalars['Int']>;
};

export type OperationsTag = {
  __typename?: 'OperationsTag';
  collection_name?: Maybe<Scalars['String']>;
  hero_image_blurhash?: Maybe<Scalars['String']>;
  hero_image_blurhash_v2?: Maybe<Scalars['String']>;
  hero_image_url?: Maybe<Scalars['String']>;
  hero_image_url_v2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_collection?: Maybe<Scalars['Boolean']>;
  is_filter?: Maybe<Scalars['Boolean']>;
  is_v2?: Maybe<Scalars['Boolean']>;
  logo_blurhash?: Maybe<Scalars['String']>;
  logo_blurhash_v2?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  logo_url_v2?: Maybe<Scalars['String']>;
  menu_item_tags?: Maybe<Array<Maybe<MenuItemTag>>>;
  name?: Maybe<Scalars['String']>;
  ordinal?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  data?: Maybe<Scalars['JSON']>;
  event_date?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Float']>;
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  has_more?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<Array<Maybe<PlacedOrder>>>;
};

export type OrderInput = {
  brand?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  estimated_pickup_time?: InputMaybe<Scalars['String']>;
  is_pickup?: InputMaybe<Scalars['Boolean']>;
  line_items?: InputMaybe<Array<InputMaybe<LineItemInput>>>;
  provider?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type OrderMenuItemModifierList = {
  __typename?: 'OrderMenuItemModifierList';
  id?: Maybe<Scalars['Int']>;
  ordinal?: Maybe<Scalars['Int']>;
  store_menu_item_id?: Maybe<Scalars['Int']>;
  store_modifier_list_id?: Maybe<Scalars['Int']>;
};

export type OrderModifierItemV2 = {
  __typename?: 'OrderModifierItemV2';
  custom_chit?: Maybe<CustomChit>;
  id?: Maybe<Scalars['Int']>;
  kitchen_name?: Maybe<Scalars['String']>;
  modifier_id?: Maybe<Scalars['Int']>;
  modifier_list_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order_menu_item_modifier_list?: Maybe<OrderMenuItemModifierList>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export enum OrderStatusV2 {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Cooking = 'COOKING',
  Created = 'CREATED',
  Delivered = 'DELIVERED',
  PickedUp = 'PICKED_UP',
  Ready = 'READY',
  Scheduled = 'SCHEDULED',
  Unaccepted = 'UNACCEPTED'
}

export enum OrderType {
  Compensation = 'COMPENSATION',
  Employee = 'EMPLOYEE',
  Marketing = 'MARKETING',
  Regular = 'REGULAR',
  Training = 'TRAINING'
}

export type OrderV2 = {
  __typename?: 'OrderV2';
  accepted_at?: Maybe<Scalars['Float']>;
  bag_count?: Maybe<Scalars['Int']>;
  bag_size?: Maybe<Scalars['Int']>;
  brand?: Maybe<BrandV2>;
  brand_name?: Maybe<Scalars['String']>;
  cooking_at?: Maybe<Scalars['Float']>;
  courier?: Maybe<CourierV2>;
  courier_status?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerV2>;
  customer_name?: Maybe<Scalars['String']>;
  customer_phone?: Maybe<Scalars['String']>;
  dining_option?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['Int']>;
  eta?: Maybe<Eta>;
  external_delivery_id?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['Float']>;
  is_completed?: Maybe<Scalars['Boolean']>;
  is_oversold?: Maybe<Scalars['Boolean']>;
  is_pickup?: Maybe<Scalars['Boolean']>;
  is_readiable?: Maybe<IsReadiable>;
  is_scheduled?: Maybe<Scalars['Boolean']>;
  line_items?: Maybe<Array<Maybe<LineItemV2>>>;
  location?: Maybe<LocationV2>;
  location_id?: Maybe<Scalars['Int']>;
  oversold_resolution?: Maybe<Resolution>;
  pickup_time?: Maybe<Scalars['Float']>;
  prep_time?: Maybe<Scalars['Int']>;
  provider?: Maybe<ProviderV2>;
  status?: Maybe<OrderStatusV2>;
  ticket_counter?: Maybe<Scalars['Int']>;
  type?: Maybe<OrderType>;
  visible_at?: Maybe<Scalars['Float']>;
};

export type OversoldItem = {
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type OversoldItemInput = {
  external_delivery_id?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<InputMaybe<OversoldItem>>>;
  order_id?: InputMaybe<Scalars['Int']>;
  provider_id?: InputMaybe<Scalars['Int']>;
};

export type PaginatedCustomers = {
  __typename?: 'PaginatedCustomers';
  customers?: Maybe<Array<Maybe<Customer>>>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PaginatedMenuEntitiesFilter = {
  display?: InputMaybe<Scalars['Boolean']>;
  field?: InputMaybe<PaginatedMenuEntitiesFilterEnum>;
  value?: InputMaybe<Scalars['String']>;
};

export enum PaginatedMenuEntitiesFilterEnum {
  BrandId = 'brand_id',
  BrandName = 'brand_name',
  ItemId = 'item_id',
  ItemName = 'item_name',
  Location = 'location',
  TargetType = 'target_type'
}

export type PaginatedMenuEntitiesInput = {
  filters?: InputMaybe<Array<InputMaybe<PaginatedMenuEntitiesFilter>>>;
  page_number?: InputMaybe<Scalars['Int']>;
  page_size?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<PaginatedMenuEntitiesSort>>>;
};

export type PaginatedMenuEntitiesSort = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<PaginatedMenuEntitiesSortOrderEnum>;
  ordinal?: InputMaybe<Scalars['Int']>;
};

export enum PaginatedMenuEntitiesSortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Group Order participant */
export type Participant = {
  __typename?: 'Participant';
  customer?: Maybe<Customer>;
  customer_id?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['String'];
  is_creator?: Maybe<Scalars['Boolean']>;
  is_done?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  raw_cart_id?: Maybe<Scalars['String']>;
};

export type PeekIngredient = {
  __typename?: 'PeekIngredient';
  display_order?: Maybe<Scalars['Float']>;
  ingredient_name?: Maybe<Scalars['String']>;
  preparation_note?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  recipe_id?: Maybe<Scalars['String']>;
  spanish_ingredient_name?: Maybe<Scalars['String']>;
  uom_name?: Maybe<Scalars['String']>;
};

export type PeekMedia = {
  __typename?: 'PeekMedia';
  build_grid_img_link?: Maybe<Scalars['String']>;
  fallback_image_link?: Maybe<Scalars['String']>;
  final_plated_img_link?: Maybe<Scalars['String']>;
  first_image_link?: Maybe<Scalars['String']>;
  second_image_link?: Maybe<Scalars['String']>;
};

export enum PickupType {
  Poolside = 'POOLSIDE',
  Regular = 'REGULAR'
}

export type PlacedOrder = {
  __typename?: 'PlacedOrder';
  brand_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  brand_name?: Maybe<Scalars['String']>;
  cart?: Maybe<Cart>;
  cooking_at?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_group_order?: Maybe<Scalars['Boolean']>;
  is_pickup?: Maybe<Scalars['Boolean']>;
  line_items?: Maybe<Array<Maybe<PlacedOrderLineItem>>>;
  location?: Maybe<LocationV2>;
  pickup_time?: Maybe<Scalars['Float']>;
  prep_time?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type PlacedOrderItemReorder = {
  __typename?: 'PlacedOrderItemReorder';
  date: Scalars['String'];
  id: Scalars['String'];
  modifier_items: Array<PlacedOrderModifierItem>;
};

export type PlacedOrderLineItem = {
  __typename?: 'PlacedOrderLineItem';
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  modifier_items?: Maybe<Array<Maybe<PlacedOrderModifierItem>>>;
  name?: Maybe<Scalars['String']>;
  ordinal?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  special_instructions?: Maybe<Scalars['String']>;
};

export type PlacedOrderModifierItem = {
  __typename?: 'PlacedOrderModifierItem';
  id: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

export enum PlatformsEnum {
  GuestKiosk = 'guest_kiosk',
  GuestMobile = 'guest_mobile',
  GuestWeb = 'guest_web',
  Kiosk = 'kiosk',
  Landing = 'landing'
}

export type PrepStationTypeV2 = {
  __typename?: 'PrepStationTypeV2';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type PrepStationV2 = {
  __typename?: 'PrepStationV2';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  prep_station_type_id?: Maybe<Scalars['Int']>;
  printer_id?: Maybe<Scalars['String']>;
  tickets?: Maybe<Array<Maybe<TicketV2>>>;
};

export enum PrepTypeEnum {
  Fryer = 'FRYER',
  Griddle = 'GRIDDLE',
  None = 'NONE',
  Regular = 'REGULAR'
}

export type PrintReceiptPayload = {
  __typename?: 'PrintReceiptPayload';
  data?: Maybe<Scalars['JSON']>;
};

export type ProviderV2 = {
  __typename?: 'ProviderV2';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  revenue_center?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type PublicBrand = {
  __typename?: 'PublicBrand';
  about?: Maybe<Scalars['String']>;
  about_media: Array<Media>;
  brand_id?: Maybe<Scalars['Int']>;
  coming_soon?: Maybe<ComingSoon>;
  cta?: Maybe<Cta>;
  cuisine?: Maybe<Scalars['String']>;
  exclusive_description: Scalars['String'];
  feature_section?: Maybe<Section>;
  food_hero_image?: Maybe<Image>;
  gallery_media: Array<Media>;
  is_exclusive: Scalars['Boolean'];
  locations?: Maybe<Array<Maybe<LocationV2>>>;
  logo?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  short_cuisine?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
};


export type PublicBrandComing_SoonArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
  location_slug?: InputMaybe<Scalars['String']>;
};

export type PublicMenu = {
  __typename?: 'PublicMenu';
  id?: Maybe<Scalars['Int']>;
  menu_categories?: Maybe<Array<Maybe<PublicMenuCategory>>>;
};

export type PublicMenuCategory = {
  __typename?: 'PublicMenuCategory';
  brand_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  menu_items?: Maybe<Array<Maybe<PublicMenuItem>>>;
  name?: Maybe<Scalars['String']>;
  ordinal?: Maybe<Scalars['Int']>;
};


export type PublicMenuCategoryMenu_ItemsArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
};

/** The most popular items this week for each store in each location */
export type PublicMenuItem = {
  __typename?: 'PublicMenuItem';
  blurhash?: Maybe<Scalars['String']>;
  brand_id?: Maybe<Scalars['Int']>;
  brand_name?: Maybe<Scalars['String']>;
  brand_slug?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  item_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Int']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  location_slug?: Maybe<Scalars['String']>;
  location_street?: Maybe<Scalars['String']>;
  menu_item_brand_id?: Maybe<Scalars['Int']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  modifier_lists?: Maybe<Array<Maybe<PublicModifierList>>>;
  name?: Maybe<Scalars['String']>;
  prep_type?: Maybe<PrepTypeEnum>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  store_id?: Maybe<Scalars['Int']>;
  store_menu_item_id?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  weekly_rank?: Maybe<Scalars['Int']>;
};

export type PublicMenuItemReorder = {
  __typename?: 'PublicMenuItemReorder';
  reorders: Array<PlacedOrderItemReorder>;
  store_menu_item_id: Scalars['Int'];
};

export type PublicModifierItem = {
  __typename?: 'PublicModifierItem';
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
};

export type PublicModifierList = {
  __typename?: 'PublicModifierList';
  help_text: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  is_single_select?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  modifier_items?: Maybe<Array<Maybe<PublicModifierItem>>>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  required: Scalars['Boolean'];
};


export type PublicModifierListModifier_ItemsArgs = {
  filter_active_modifiers?: InputMaybe<Scalars['Boolean']>;
  is_delivery?: InputMaybe<Scalars['Boolean']>;
};

export type PublicStore = {
  __typename?: 'PublicStore';
  brand?: Maybe<BrandV2>;
  display_hours?: Maybe<Array<Maybe<DisplayHours>>>;
  id?: Maybe<Scalars['Int']>;
  is_open?: Maybe<Scalars['Boolean']>;
  is_within_hours?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationV2>;
  menus?: Maybe<Array<Maybe<PublicMenu>>>;
  most_popular?: Maybe<Array<Maybe<PublicMenuItem>>>;
  most_recently_added?: Maybe<Array<Maybe<PublicMenuItem>>>;
  ordinal?: Maybe<Scalars['Int']>;
  store_hours?: Maybe<Array<Maybe<StoreHours>>>;
  utensils?: Maybe<Array<Maybe<PublicMenuItem>>>;
};


export type PublicStoreMost_Recently_AddedArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
};


export type PublicStoreUtensilsArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  /** Get active banners for guest web platform */
  active_banners?: Maybe<Array<Banner>>;
  /** Get all active brands */
  active_brands?: Maybe<Array<Maybe<BrandV2>>>;
  /** Get active (status: 'OPEN') cart for given user and location */
  active_cart?: Maybe<Cart>;
  /** Get active editorial modules for guest web platform */
  active_editorial_modules?: Maybe<Array<EditorialModule>>;
  /** Get active marketing billboards */
  active_marketing_billboards: Array<MarketingBillboard>;
  /** Get order status after customer places order */
  active_order?: Maybe<ActiveOrder>;
  /** Get current active reward that will be applied in next order */
  active_reward?: Maybe<CustomerReward>;
  /** Get Address Details & Delivery Estimates by Google Maps Place Id */
  address_and_delivery_estimates_by_place_id?: Maybe<AddressAndDeliveryEstimates>;
  /** Get delivery estimates for a delivery address */
  address_delivery_estimates?: Maybe<Array<Maybe<DeliveryEstimate>>>;
  /** Get Google Maps Places details for given address */
  address_details?: Maybe<AddressDetails>;
  all_location_details?: Maybe<AllLocationDetailOutput>;
  /** Return all store, regardless of is_active */
  all_stores?: Maybe<Array<Maybe<StoreV2>>>;
  allergens_by_menu_item_id?: Maybe<Array<Maybe<Allergen>>>;
  /** Get available promos that a user can used (must qualify and not used previously) */
  available_promos?: Maybe<Array<Maybe<Coupon>>>;
  /** Get all brands */
  brands?: Maybe<Array<Maybe<BrandV2>>>;
  /** Get all ingredients for a given menu item/modifier item */
  build_steps?: Maybe<Array<Maybe<BuildStep>>>;
  bulk_update_menu_entities_status?: Maybe<Scalars['Boolean']>;
  calculate_tip_amount?: Maybe<TipAmount>;
  /** DEPRECATED! Use cart_v2 instead */
  cart?: Maybe<Cart>;
  cart_receipt?: Maybe<CartReceipt>;
  /** Get reward applied to cart */
  cart_reward?: Maybe<CustomerReward>;
  /** Get an existing. Don't create new one */
  cart_v2?: Maybe<Cart>;
  client_menu_item_pairings?: Maybe<Array<Maybe<PublicMenuItem>>>;
  /** Get today's completed order count */
  completed_order_count?: Maybe<Scalars['Int']>;
  cook?: Maybe<Employee>;
  /** Get a coupon by ID */
  coupon?: Maybe<Coupon>;
  /** Get list of coupons */
  coupons?: Maybe<Coupons>;
  /** Gets customer with given auth0_user_id. Customer is created if not found */
  current_user?: Maybe<Customer>;
  custom_chit_tickets_statuses?: Maybe<Array<Maybe<CustomChitTicket>>>;
  custom_hours?: Maybe<Array<Maybe<CustomHours>>>;
  /** Get information about customer (profile) */
  customer?: Maybe<Customer>;
  /** Get paginated customer data */
  customers?: Maybe<Customers>;
  /** Get delivery address by ID */
  delivery_address?: Maybe<DeliveryAddress>;
  /** Get customer delivery addresses */
  delivery_addresses?: Maybe<Array<Maybe<DeliveryAddress>>>;
  /** Get delivery estimates for an anonymous delivery address */
  delivery_estimates?: Maybe<Array<Maybe<DeliveryEstimate>>>;
  /** Delivery price change message */
  delivery_price_change_message?: Maybe<Scalars['String']>;
  employee?: Maybe<Employee>;
  employees?: Maybe<EmployeesOutput>;
  faqCollection?: Maybe<FaqCollectionItems>;
  featured_items: Array<PublicMenuItem>;
  geocodes_by_zipcode?: Maybe<GooglePlaceGeocodeCoord>;
  /** Get Attached catering order by order id */
  get_catering_order?: Maybe<OperationsCateringOrder>;
  get_custom_tip_and_rate?: Maybe<TipAndRate>;
  /** Get Doordash tracking URL by cart ID */
  get_doordash_tracking_url?: Maybe<Scalars['String']>;
  get_recent_auto_deactivated_items?: Maybe<Array<Maybe<AutoDeactivatedItem>>>;
  /** Get Group orders by status */
  group_order_carts?: Maybe<Array<Maybe<Cart>>>;
  /** Get guest feedback channels */
  guest_feedback_channels?: Maybe<Array<Maybe<GuestFeedbackChannel>>>;
  /** Get all ingredients for a given menu item/modifier item */
  ingredients_by_item_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get issue categories */
  issue_categories?: Maybe<Array<Maybe<IssueCategory>>>;
  item_ingredient_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  item_ingredient_list_by_menu_item_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  kiosk_active_banners?: Maybe<Array<Banner>>;
  kiosk_cart_validation?: Maybe<Array<Maybe<KioskInvalidItem>>>;
  kiosk_locations?: Maybe<Array<Maybe<LocationV2>>>;
  kiosks_status?: Maybe<KiosksStatus>;
  landingPageBrandSectionCollection?: Maybe<LandingPageBrandSectionItems>;
  /** Get active banners for landing platform */
  landing_active_banners?: Maybe<Array<Banner>>;
  location?: Maybe<LocationV2>;
  /** Get location info based on email */
  location_account?: Maybe<LocationAccount>;
  /** List of location accounts */
  location_accounts?: Maybe<Array<Maybe<LocationAccountOutput>>>;
  /** Get location by slug */
  location_by_slug?: Maybe<LocationV2>;
  /** Get tags based on location */
  location_tags?: Maybe<Array<Maybe<Tag>>>;
  location_tags_2?: Maybe<Array<Maybe<Tag>>>;
  /** Get locations */
  locations?: Maybe<Array<Maybe<LocationV2>>>;
  /** Get loyalty account info (profile) */
  loyalty_account?: Maybe<LoyaltyAccount>;
  menu_item_pairings?: Maybe<Array<Maybe<MenuItemPairing>>>;
  /** Get menu items */
  menu_items?: Maybe<Array<Maybe<PublicMenuItem>>>;
  /** List out the menu sync events */
  menu_sync_events?: Maybe<Array<Maybe<MenuSyncEvent>>>;
  menu_tag?: Maybe<Tag>;
  /** Get menu tags */
  menu_tags?: Maybe<Array<Maybe<Tag>>>;
  /** Get active banners for guest mobile platform */
  mobile_active_banners?: Maybe<Array<Banner>>;
  mobile_min_version_satisfied: Scalars['Boolean'];
  /** Fetch a list of top 6 StoreMenuItems for each active location */
  most_popular_items?: Maybe<Array<Maybe<StoreMenuItem>>>;
  /** Get all of our menu items */
  operations_all_menu_items?: Maybe<Array<Maybe<MenuItem>>>;
  operations_brands?: Maybe<Array<Maybe<OperationsBrand>>>;
  operations_brands_list?: Maybe<Array<Maybe<OperationsBrandListItem>>>;
  /** Get list of marketing billboards for operations */
  operations_marketing_billboards?: Maybe<Array<Maybe<MarketingBillboard>>>;
  operations_menu?: Maybe<Array<Maybe<OperationsMenu>>>;
  /** Get menu items by locations */
  operations_menu_items?: Maybe<Array<Maybe<EditorialMenuItem>>>;
  /** Get tags */
  operations_tags?: Maybe<Array<Maybe<OperationsTag>>>;
  /** Get order by Cart ID */
  order?: Maybe<OrderV2>;
  /** Get order count for phone number */
  order_count?: Maybe<Scalars['Int']>;
  /** List out order sync events */
  order_events?: Maybe<Array<Maybe<OrderEvent>>>;
  /** Get order history of customer (profile) */
  order_history?: Maybe<Array<Maybe<PlacedOrder>>>;
  /** Get store orders base don phone */
  order_history_by_phone?: Maybe<Array<Maybe<StoreOrder>>>;
  /** Low cost inquiry into order history status for a specific brand */
  order_history_contains_brand?: Maybe<Scalars['Boolean']>;
  /** Get order history of customer with limit */
  order_history_with_limit?: Maybe<OrderHistory>;
  /** Get normalized orders injected through provider APIs */
  orders?: Maybe<Array<Maybe<OrderV2>>>;
  paginated_menu_entities?: Maybe<Array<Maybe<MenuEntity>>>;
  peek_ingredients?: Maybe<Array<Maybe<PeekIngredient>>>;
  peek_media?: Maybe<PeekMedia>;
  /** Get Google Maps Place Predictions */
  place_predictions?: Maybe<Array<Maybe<GooglePlaceGeocode>>>;
  prep_station?: Maybe<PrepStationV2>;
  prep_station_chits: Array<Chit>;
  prep_station_types?: Maybe<Array<Maybe<PrepStationTypeV2>>>;
  /** List of location printers */
  printers?: Maybe<Array<Maybe<LocationPrinterOutput>>>;
  /** Get all stores including deactivated */
  private_stores?: Maybe<Array<Maybe<PublicStore>>>;
  /** Supported providers */
  providers?: Maybe<Array<Maybe<ProviderV2>>>;
  /** Get information regarding stores including their menus */
  public_brands?: Maybe<Array<Maybe<PublicBrand>>>;
  /** Get information single menu item */
  public_menu_item?: Maybe<PublicMenuItem>;
  /** Get previous ordered modifiers for given customer */
  public_menu_item_reorders: Array<PublicMenuItemReorder>;
  /** Get information for list of menu item */
  public_menu_items?: Maybe<Array<Maybe<PublicMenuItem>>>;
  /** Get information regarding store including its menus */
  public_store?: Maybe<PublicStore>;
  public_stores?: Maybe<Array<Maybe<PublicStore>>>;
  /** Get order status after customer places order */
  raw_order?: Maybe<RawOrder>;
  recently_ordered?: Maybe<Array<Maybe<PublicMenuItem>>>;
  /** Deprecated: Referral program was removed */
  referral_code?: Maybe<ReferralCode>;
  /** Deprecated: Referral program was removed */
  referral_codes?: Maybe<Array<Maybe<ReferralCode>>>;
  /** Get related refunds for a given payment intent */
  related_refunds?: Maybe<Array<Maybe<Refund>>>;
  /** Get information single menu item regarding a reward */
  reward_menu_item?: Maybe<RewardMenuItem>;
  /** Get information all menu items regarding a reward */
  reward_menu_items?: Maybe<Array<Maybe<RewardMenuItem>>>;
  /** Get rewards available for purchase */
  rewards?: Maybe<Array<Maybe<Reward>>>;
  /** Get sale by id */
  sale_by_id?: Maybe<Sale>;
  /** Get paginated sales data */
  sales?: Maybe<Array<Maybe<Sale>>>;
  scanned_line_item?: Maybe<Array<Maybe<ScannedLineItem>>>;
  scheduled_order_notifications?: Maybe<Array<Maybe<Notification>>>;
  /** Search menu items */
  search_menu_items?: Maybe<SearchMenuItemsResult>;
  second_generation_store_order_features?: Maybe<SecondGenerationStoreOrderEtaFeatures>;
  sent_survey?: Maybe<SentSurvey>;
  /** Return given store by id */
  store_by_id?: Maybe<StoreV2>;
  /** Get store location by slug */
  store_location?: Maybe<LocationV2>;
  /** Get information for single store menu item given menu item and location */
  store_menu_item?: Maybe<PublicMenuItem>;
  /** Fetch a list of StoreMenuItems given a menu_item_id */
  store_menu_items?: Maybe<Array<Maybe<StoreMenuItem>>>;
  /** Get sum menu item prep time for store order */
  store_order_eta_features?: Maybe<StoreOrderEtaFeatures>;
  /** Get name on line item order */
  store_order_line_item_name?: Maybe<StoreOrderLineItemName>;
  store_orders_statuses?: Maybe<Array<Maybe<OrderV2>>>;
  stored_payment_methods?: Maybe<Array<Maybe<StoredPaymentMethod>>>;
  /** Get information regarding store including its menu */
  stores?: Maybe<Array<Maybe<StoreV2>>>;
  stripe_terminals?: Maybe<Array<Maybe<StripeTerminalReader>>>;
  /** Get tag based on location by id or slug */
  tag?: Maybe<Tag>;
  ticket_orders?: Maybe<Array<Maybe<KdsTicket>>>;
  toast_ids_by_location?: Maybe<Array<Maybe<ToastMenuOutput>>>;
};


export type QueryActive_BannersArgs = {
  brandSlug?: InputMaybe<Scalars['String']>;
  isNewUser?: InputMaybe<Scalars['Boolean']>;
  locationSlug?: InputMaybe<Scalars['String']>;
  loggedIn?: InputMaybe<Scalars['Boolean']>;
};


export type QueryActive_CartArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  cart_id?: InputMaybe<Scalars['String']>;
  force_new?: InputMaybe<Scalars['Boolean']>;
  location_slug?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<GuestOrderType>;
};


export type QueryActive_Editorial_ModulesArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  locationSlug?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<PlatformsEnum>;
};


export type QueryActive_Marketing_BillboardsArgs = {
  location_slug: Scalars['String'];
  logged_in: Scalars['Boolean'];
  platform: Scalars['String'];
};


export type QueryActive_OrderArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
};


export type QueryActive_RewardArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
};


export type QueryAddress_And_Delivery_Estimates_By_Place_IdArgs = {
  place_id?: InputMaybe<Scalars['String']>;
};


export type QueryAddress_Delivery_EstimatesArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  delivery_address?: InputMaybe<DeliveryAddressInput>;
  location_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryAddress_DetailsArgs = {
  place_id?: InputMaybe<Scalars['String']>;
};


export type QueryAll_Location_DetailsArgs = {
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryAllergens_By_Menu_Item_IdArgs = {
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryAvailable_PromosArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
};


export type QueryBrandsArgs = {
  location_slug?: InputMaybe<Scalars['String']>;
};


export type QueryBuild_StepsArgs = {
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryBulk_Update_Menu_Entities_StatusArgs = {
  start_timestamp?: InputMaybe<Scalars['String']>;
};


export type QueryCalculate_Tip_AmountArgs = {
  subtotal?: InputMaybe<Scalars['Int']>;
  tipRate?: InputMaybe<Scalars['Int']>;
};


export type QueryCartArgs = {
  force_new?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  location_slug?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<GuestOrderType>;
  pickup_type?: InputMaybe<PickupType>;
};


export type QueryCart_ReceiptArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  provider_id?: InputMaybe<Scalars['Int']>;
};


export type QueryCart_RewardArgs = {
  external_id?: InputMaybe<Scalars['String']>;
};


export type QueryCart_V2Args = {
  id?: InputMaybe<Scalars['String']>;
  location_slug?: InputMaybe<Scalars['String']>;
};


export type QueryClient_Menu_Item_PairingsArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  location_slug?: InputMaybe<Scalars['String']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryCompleted_Order_CountArgs = {
  location_slug?: InputMaybe<Scalars['String']>;
};


export type QueryCookArgs = {
  cook_id?: InputMaybe<Scalars['Int']>;
};


export type QueryCouponArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryCouponsArgs = {
  input?: InputMaybe<CouponsInput>;
};


export type QueryCurrent_UserArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  referral_code?: InputMaybe<Scalars['String']>;
};


export type QueryCustom_Chit_Tickets_StatusesArgs = {
  custom_chit_ticket_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryCustomerArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
};


export type QueryCustomersArgs = {
  input?: InputMaybe<CustomersInput>;
};


export type QueryDelivery_AddressArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryDelivery_AddressesArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
};


export type QueryDelivery_EstimatesArgs = {
  city?: InputMaybe<Scalars['String']>;
  location_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};


export type QueryEmployeeArgs = {
  employee_id?: InputMaybe<Scalars['Int']>;
};


export type QueryEmployeesArgs = {
  input?: InputMaybe<EmployeesInput>;
};


export type QueryFeatured_ItemsArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  location_id: Scalars['Int'];
  only_manual?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGeocodes_By_ZipcodeArgs = {
  zipcode?: InputMaybe<Scalars['String']>;
};


export type QueryGet_Catering_OrderArgs = {
  external_id: Scalars['String'];
};


export type QueryGet_Custom_Tip_And_RateArgs = {
  displayTip?: InputMaybe<Scalars['String']>;
  subtotal?: InputMaybe<Scalars['Int']>;
};


export type QueryGet_Doordash_Tracking_UrlArgs = {
  cart_id: Scalars['String'];
};


export type QueryGet_Recent_Auto_Deactivated_ItemsArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
};


export type QueryGroup_Order_CartsArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  cart_status?: InputMaybe<Scalars['String']>;
};


export type QueryIngredients_By_Item_IdsArgs = {
  menu_item_id?: InputMaybe<Scalars['String']>;
  modifier_item_id?: InputMaybe<Scalars['String']>;
};


export type QueryItem_Ingredient_ListArgs = {
  menu_item_id?: InputMaybe<Scalars['Int']>;
  modifier_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryItem_Ingredient_List_By_Menu_Item_IdArgs = {
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryKiosk_Active_BannersArgs = {
  locationSlug?: InputMaybe<Scalars['String']>;
};


export type QueryKiosk_Cart_ValidationArgs = {
  cart?: InputMaybe<KioskCart>;
};


export type QueryKiosks_StatusArgs = {
  location_slug?: InputMaybe<Scalars['String']>;
};


export type QueryLanding_Active_BannersArgs = {
  is_new_user?: InputMaybe<Scalars['Boolean']>;
  logged_in?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocationArgs = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
};


export type QueryLocation_AccountArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type QueryLocation_AccountsArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
};


export type QueryLocation_By_SlugArgs = {
  location_slug?: InputMaybe<Scalars['String']>;
};


export type QueryLocation_TagsArgs = {
  is_v2?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLocation_Tags_2Args = {
  is_v2?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLoyalty_AccountArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
};


export type QueryMenu_Item_PairingsArgs = {
  menuItemId: Scalars['Int'];
};


export type QueryMenu_ItemsArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
  location_slug?: InputMaybe<Scalars['String']>;
};


export type QueryMenu_TagArgs = {
  location_id: Scalars['Int'];
  slug: Scalars['String'];
};


export type QueryMenu_TagsArgs = {
  is_v2?: InputMaybe<Scalars['Boolean']>;
  location_slug: Scalars['String'];
};


export type QueryMobile_Active_BannersArgs = {
  brandSlug?: InputMaybe<Scalars['String']>;
  isNewUser?: InputMaybe<Scalars['Boolean']>;
  locationSlug?: InputMaybe<Scalars['String']>;
  loggedIn?: InputMaybe<Scalars['Boolean']>;
  store_status?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOperations_BrandsArgs = {
  input?: InputMaybe<OperationsBrandsInput>;
};


export type QueryOperations_Menu_ItemsArgs = {
  location_id: Scalars['Int'];
};


export type QueryOrderArgs = {
  cart_id?: InputMaybe<Scalars['String']>;
};


export type QueryOrder_CountArgs = {
  phone_number?: InputMaybe<Scalars['String']>;
};


export type QueryOrder_EventsArgs = {
  external_id?: InputMaybe<Scalars['String']>;
};


export type QueryOrder_HistoryArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
};


export type QueryOrder_History_By_PhoneArgs = {
  customer_phone?: InputMaybe<Scalars['String']>;
};


export type QueryOrder_History_Contains_BrandArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  brand_slug?: InputMaybe<Scalars['String']>;
  complete?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOrder_History_With_LimitArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  complete?: InputMaybe<Scalars['Boolean']>;
  guest_providers_only?: InputMaybe<Scalars['Boolean']>;
  is_pickup?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryOrdersArgs = {
  filter_completed?: InputMaybe<Scalars['Boolean']>;
  location_slug?: InputMaybe<Scalars['String']>;
};


export type QueryPaginated_Menu_EntitiesArgs = {
  input?: InputMaybe<PaginatedMenuEntitiesInput>;
};


export type QueryPeek_IngredientsArgs = {
  is_peek_v2?: InputMaybe<Scalars['Boolean']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPeek_MediaArgs = {
  is_peek_v2?: InputMaybe<Scalars['Boolean']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPlace_PredictionsArgs = {
  coord?: InputMaybe<Coordinate>;
  input?: InputMaybe<Scalars['String']>;
};


export type QueryPrep_StationArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPrep_Station_ChitsArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPrintersArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPrivate_StoresArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
  location_slug?: InputMaybe<Scalars['String']>;
  store_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryPublic_BrandsArgs = {
  include_localkitchens?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPublic_Menu_ItemArgs = {
  filter_active_modifiers?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  is_delivery?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPublic_Menu_Item_ReordersArgs = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPublic_Menu_ItemsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  include_inactive?: InputMaybe<Scalars['Boolean']>;
  is_delivery?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPublic_StoreArgs = {
  brand_slug?: InputMaybe<Scalars['String']>;
  location_slug?: InputMaybe<Scalars['String']>;
  store_id?: InputMaybe<Scalars['Int']>;
};


export type QueryPublic_StoresArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
  location_slug?: InputMaybe<Scalars['String']>;
  show_inactive?: InputMaybe<Scalars['Boolean']>;
  store_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryRaw_OrderArgs = {
  external_id?: InputMaybe<Scalars['String']>;
};


export type QueryRecently_OrderedArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  location_id: Scalars['Int'];
};


export type QueryReferral_CodeArgs = {
  code?: InputMaybe<Scalars['String']>;
  referrer_id?: InputMaybe<Scalars['Int']>;
};


export type QueryReferral_CodesArgs = {
  referrer_id?: InputMaybe<Scalars['Int']>;
};


export type QueryRelated_RefundsArgs = {
  payment_intent_id?: InputMaybe<Scalars['String']>;
};


export type QueryReward_Menu_ItemArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryReward_Menu_ItemsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryRewardsArgs = {
  locationSlug?: InputMaybe<Scalars['String']>;
};


export type QuerySale_By_IdArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QuerySalesArgs = {
  brand_id?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['Float']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  location_id?: InputMaybe<Scalars['String']>;
  page_number?: InputMaybe<Scalars['Int']>;
  phone_number?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['Float']>;
  ticket_number?: InputMaybe<Scalars['String']>;
};


export type QueryScanned_Line_ItemArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
};


export type QueryScheduled_Order_NotificationsArgs = {
  prep_station_id?: InputMaybe<Scalars['Int']>;
};


export type QuerySearch_Menu_ItemsArgs = {
  filters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  is_internal?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QuerySecond_Generation_Store_Order_FeaturesArgs = {
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type QuerySent_SurveyArgs = {
  short_id?: InputMaybe<Scalars['String']>;
};


export type QueryStore_By_IdArgs = {
  store_id?: InputMaybe<Scalars['Int']>;
};


export type QueryStore_LocationArgs = {
  location_slug: Scalars['String'];
};


export type QueryStore_Menu_ItemArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryStore_Menu_ItemsArgs = {
  menu_item_id?: InputMaybe<Scalars['Int']>;
};


export type QueryStore_Order_Eta_FeaturesArgs = {
  store_order_id?: InputMaybe<Scalars['Int']>;
};


export type QueryStore_Order_Line_Item_NameArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryStore_Orders_StatusesArgs = {
  store_order_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryStoresArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
  location_slug?: InputMaybe<Scalars['String']>;
  show_all?: InputMaybe<Scalars['Boolean']>;
  store_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryStripe_TerminalsArgs = {
  locationId?: InputMaybe<Scalars['Int']>;
};


export type QueryTagArgs = {
  id?: InputMaybe<Scalars['Int']>;
  is_v2?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryTicket_OrdersArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
};


export type QueryToast_Ids_By_LocationArgs = {
  location_id?: InputMaybe<Scalars['Int']>;
};

export type Question = {
  __typename?: 'Question';
  delivery: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  follow_up_question_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  is_active?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  ordinality: Scalars['Int'];
  sent_survey_parameters_id: Scalars['Int'];
  target_class: Scalars['String'];
  type: QuestionType;
  validation_operator?: Maybe<Scalars['String']>;
  validation_value?: Maybe<Scalars['Int']>;
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  id: Scalars['Int'];
  is_active?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  question_id: Scalars['Int'];
};

export enum QuestionType {
  FiveStar = 'FIVE_STAR',
  MultiSelect = 'MULTI_SELECT',
  ShortAnswer = 'SHORT_ANSWER',
  SingleSelect = 'SINGLE_SELECT',
  Submit = 'SUBMIT',
  ThumbsUpDown = 'THUMBS_UP_DOWN'
}

export type RawOrder = {
  __typename?: 'RawOrder';
  data?: Maybe<Scalars['JSON']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export enum ReaderNetworkStatus {
  Offline = 'offline',
  Online = 'online'
}

export type Recipe = {
  __typename?: 'Recipe';
  container_amount?: Maybe<Scalars['String']>;
  container_name?: Maybe<Scalars['String']>;
  cost_per_servings?: Maybe<Scalars['Float']>;
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  mass_amount?: Maybe<Scalars['Float']>;
  mass_unit?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recipe_book_id?: Maybe<Scalars['String']>;
  recipe_id?: Maybe<Scalars['String']>;
  recipe_steps?: Maybe<Array<Maybe<RecipeStep>>>;
  sub_recipes?: Maybe<Array<Maybe<SubRecipe>>>;
  target_id?: Maybe<Scalars['Int']>;
  target_type?: Maybe<Scalars['String']>;
  volume_amount?: Maybe<Scalars['Float']>;
  volume_name?: Maybe<Scalars['String']>;
};

export type RecipeStep = {
  __typename?: 'RecipeStep';
  description?: Maybe<Scalars['String']>;
  display_order?: Maybe<Scalars['Int']>;
  is_header?: Maybe<Scalars['Boolean']>;
  is_note?: Maybe<Scalars['Boolean']>;
  recipe_id?: Maybe<Scalars['String']>;
  recipestep_id?: Maybe<Scalars['String']>;
};

export type Referral = {
  __typename?: 'Referral';
  id?: Maybe<Scalars['Int']>;
  redeemed_at?: Maybe<Scalars['String']>;
};

export type ReferralCode = {
  __typename?: 'ReferralCode';
  credits_earned?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referral_code?: Maybe<Scalars['String']>;
  referral_program?: Maybe<ReferralProgram>;
  referrals?: Maybe<Array<Maybe<Referral>>>;
};

export type ReferralProgram = {
  __typename?: 'ReferralProgram';
  customer_credits?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  referrer_credits?: Maybe<Scalars['Int']>;
};

export type Refund = {
  __typename?: 'Refund';
  amount: Scalars['Float'];
  date: Scalars['String'];
  id: Scalars['String'];
  payment_intent_id: Scalars['String'];
};

export type RemoveItemInput = {
  cart_id?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
};

export type Resolution = {
  __typename?: 'Resolution';
  is_resolved?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type Reward = {
  __typename?: 'Reward';
  attrs?: Maybe<Scalars['JSON']>;
  brand_attrs?: Maybe<Scalars['JSON']>;
  color?: Maybe<Scalars['String']>;
  delivery_attrs?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  discount_method?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_purchasable?: Maybe<Scalars['Boolean']>;
  max_amount?: Maybe<Scalars['Int']>;
  minimum_subtotal?: Maybe<Scalars['Int']>;
  mobile_icon_library?: Maybe<Scalars['String']>;
  mobile_icon_slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percent_based_attrs?: Maybe<Scalars['JSON']>;
  redemption_price?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  times_used?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  ui_attrs?: Maybe<Scalars['JSON']>;
  value?: Maybe<Scalars['Int']>;
  value_based_attrs?: Maybe<Scalars['JSON']>;
  web_icon_library?: Maybe<Scalars['String']>;
  web_icon_slug?: Maybe<Scalars['String']>;
};

export type RewardMenuItem = {
  __typename?: 'RewardMenuItem';
  id?: Maybe<Scalars['Int']>;
  menu_item_id?: Maybe<Scalars['Int']>;
};

export enum Role {
  Admin = 'ADMIN',
  Engineer = 'ENGINEER',
  Kds = 'KDS',
  Kiosk = 'KIOSK',
  Kitchen = 'KITCHEN',
  Manager = 'MANAGER',
  StatusBoard = 'STATUS_BOARD'
}

export type Sale = {
  __typename?: 'Sale';
  brands?: Maybe<Array<Maybe<BrandV2>>>;
  catering_adjustment?: Maybe<CateringAdjustment>;
  customer_id?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_phone?: Maybe<Scalars['String']>;
  external_delivery_id?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['String']>;
  is_completed?: Maybe<Scalars['Boolean']>;
  is_oversold?: Maybe<Scalars['Boolean']>;
  is_pickup?: Maybe<Scalars['Boolean']>;
  is_resolved?: Maybe<Scalars['Boolean']>;
  is_scheduled?: Maybe<Scalars['Boolean']>;
  line_items?: Maybe<Array<Maybe<LineItemV2>>>;
  location?: Maybe<LocationV2>;
  pickup_time?: Maybe<Scalars['Float']>;
  provider?: Maybe<ProviderV2>;
  raw_cart_credits_used?: Maybe<Scalars['Int']>;
  raw_cart_delivery_fee?: Maybe<Scalars['Int']>;
  raw_cart_discount_amount?: Maybe<Scalars['Int']>;
  raw_cart_service_fee?: Maybe<Scalars['Int']>;
  raw_cart_service_fee_rate?: Maybe<Scalars['Int']>;
  raw_cart_subtotal?: Maybe<Scalars['Int']>;
  raw_cart_tax?: Maybe<Scalars['Int']>;
  raw_cart_tip?: Maybe<Scalars['Int']>;
  raw_cart_total?: Maybe<Scalars['Int']>;
  raw_orders_data?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  store_order_issues?: Maybe<Array<Maybe<StoreOrderIssue>>>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['String']>;
  ticket_counter?: Maybe<Scalars['Int']>;
  total_order_count?: Maybe<Scalars['Int']>;
};

export type ScannedLineItem = {
  __typename?: 'ScannedLineItem';
  event_id?: Maybe<Scalars['Int']>;
  store_order_id?: Maybe<Scalars['Int']>;
  store_order_line_item_id?: Maybe<Scalars['Int']>;
};

export type SearchMenuItemsResult = {
  __typename?: 'SearchMenuItemsResult';
  items?: Maybe<Array<Maybe<PublicMenuItem>>>;
  query_id?: Maybe<Scalars['String']>;
};


export type SearchMenuItemsResultItemsArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
};

export type SecondGenerationStoreOrderEtaFeatures = {
  __typename?: 'SecondGenerationStoreOrderETAFeatures';
  BOTTLE_NECK_ITEMS_IN_FLIGHT?: Maybe<Scalars['Float']>;
  MAX_STATION_TIME_TO_PREP_ITEMS_IN_FLIGHT?: Maybe<Scalars['Float']>;
  STORE_MAX_STATION_TIME_TO_PREP?: Maybe<Scalars['Float']>;
  STORE_SUM_TIME_TO_PREP?: Maybe<Scalars['Float']>;
  SUM_TIME_TO_PREP_ITEMS_IN_FLIGHT?: Maybe<Scalars['Float']>;
};

export type Section = {
  __typename?: 'Section';
  body: Scalars['String'];
  cta?: Maybe<Cta>;
  header: Scalars['String'];
  image?: Maybe<Image>;
};

export type SendVerificationTokenOutput = {
  __typename?: 'SendVerificationTokenOutput';
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

export type SentSurvey = {
  __typename?: 'SentSurvey';
  id: Scalars['Int'];
  inserted_at: Scalars['String'];
  phone_number: Scalars['String'];
  questions?: Maybe<Array<Maybe<SentSurveyQuestion>>>;
  sent_survey_parameters_id: Scalars['Int'];
  short_id: Scalars['String'];
  store_order?: Maybe<OrderV2>;
  store_order_id: Scalars['Int'];
  submitted_at?: Maybe<Scalars['String']>;
  survey_link: Scalars['String'];
  updated_at: Scalars['String'];
};

export type SentSurveyParameters = {
  __typename?: 'SentSurveyParameters';
  description: Scalars['String'];
  id: Scalars['Int'];
  inserted_at?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  schedule_in?: Maybe<Scalars['Int']>;
  sms_prompt: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type SentSurveyQuestion = {
  __typename?: 'SentSurveyQuestion';
  description?: Maybe<Scalars['String']>;
  has_follow_up: Scalars['Boolean'];
  id: Scalars['Int'];
  inserted_at: Scalars['String'];
  is_required: Scalars['Boolean'];
  label: Scalars['String'];
  options?: Maybe<Array<Maybe<QuestionOption>>>;
  ordinality: Scalars['Int'];
  question_id: Scalars['Int'];
  response_submitted_at?: Maybe<Scalars['String']>;
  response_text?: Maybe<Scalars['String']>;
  response_value?: Maybe<Scalars['Int']>;
  sent_survey_id: Scalars['Int'];
  target_id: Scalars['Int'];
  type: QuestionType;
  updated_at: Scalars['String'];
  validation_operator?: Maybe<Scalars['String']>;
  validation_value?: Maybe<Scalars['Int']>;
};

export type ServiceHours = {
  __typename?: 'ServiceHours';
  end_day?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['String']>;
  start_day?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['String']>;
};

export type ServiceHoursInput = {
  end_day?: InputMaybe<Scalars['Int']>;
  end_time?: InputMaybe<Scalars['String']>;
  start_day?: InputMaybe<Scalars['Int']>;
  start_time?: InputMaybe<Scalars['String']>;
};

export type StoreHours = {
  __typename?: 'StoreHours';
  day_of_week?: Maybe<Scalars['Int']>;
  hours?: Maybe<Array<Maybe<ServiceHours>>>;
  id?: Maybe<Scalars['Int']>;
  override_date?: Maybe<Scalars['String']>;
};

export type StoreHoursInput = {
  hours?: InputMaybe<Array<InputMaybe<ServiceHoursInput>>>;
  id?: InputMaybe<Scalars['Int']>;
};

export type StoreHoursOutput = {
  __typename?: 'StoreHoursOutput';
  store_hours?: Maybe<Array<Maybe<StoreHours>>>;
};

export type StoreMenuCategoryPrepStationV2 = {
  __typename?: 'StoreMenuCategoryPrepStationV2';
  id?: Maybe<Scalars['Int']>;
  prep_station?: Maybe<PrepStationV2>;
  store_menu_category_id?: Maybe<Scalars['Int']>;
};

/** The most popular items this week for each store in each location */
export type StoreMenuItem = {
  __typename?: 'StoreMenuItem';
  brand_id?: Maybe<Scalars['Int']>;
  brand_logo?: Maybe<Image>;
  brand_name?: Maybe<Scalars['String']>;
  brand_slug?: Maybe<Scalars['String']>;
  direct_price?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  item_description?: Maybe<Scalars['String']>;
  item_image?: Maybe<Image>;
  item_name?: Maybe<Scalars['String']>;
  item_price?: Maybe<Scalars['Int']>;
  location?: Maybe<LocationV2>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  location_slug?: Maybe<Scalars['String']>;
  location_street?: Maybe<Scalars['String']>;
  menu_item_id?: Maybe<Scalars['Int']>;
  ordinal?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  store?: Maybe<OperationsStore>;
  store_menu_category_id?: Maybe<Scalars['Int']>;
  store_menu_item_id?: Maybe<Scalars['Int']>;
  tax_rate?: Maybe<Scalars['Int']>;
  toast_menu_item_id?: Maybe<Scalars['Int']>;
  weekly_rank?: Maybe<Scalars['Int']>;
};

export type StoreMenuItemPrepStationV2 = {
  __typename?: 'StoreMenuItemPrepStationV2';
  id?: Maybe<Scalars['Int']>;
  prep_station?: Maybe<PrepStationV2>;
  store_menu_item_id?: Maybe<Scalars['Int']>;
};

export type StoreMenuPrepStationV2 = {
  __typename?: 'StoreMenuPrepStationV2';
  id?: Maybe<Scalars['Int']>;
  prep_station?: Maybe<PrepStationV2>;
  store_menu_id?: Maybe<Scalars['Int']>;
};

export type StoreModifierItem = {
  __typename?: 'StoreModifierItem';
  display_to_guests?: Maybe<Scalars['Boolean']>;
  modifier_item_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  store_id?: Maybe<Scalars['String']>;
};

export type StoreOrder = {
  __typename?: 'StoreOrder';
  id?: Maybe<Scalars['String']>;
};

export type StoreOrderEtaFeatures = {
  __typename?: 'StoreOrderETAFeatures';
  DAY?: Maybe<Scalars['Int']>;
  DOMINANT_BRAND_ITEMS_IN_FLIGHT?: Maybe<Scalars['Int']>;
  HOUR?: Maybe<Scalars['Int']>;
  LOCATION_ID?: Maybe<Scalars['Int']>;
  LOCATION_ITEMS_IN_FLIGHT?: Maybe<Scalars['Int']>;
  LOCATION_ORDERS_IN_FLIGHT?: Maybe<Scalars['Int']>;
  MAX_STATION_TIME_TO_PREP?: Maybe<Scalars['Float']>;
  PREP_ITEMS?: Maybe<Scalars['Int']>;
  PREP_PRICE?: Maybe<Scalars['Float']>;
  SUM_TIME_TO_PREP?: Maybe<Scalars['Float']>;
};

export type StoreOrderIssue = {
  __typename?: 'StoreOrderIssue';
  comment?: Maybe<Scalars['String']>;
  guest_feedback?: Maybe<Scalars['String']>;
  guest_feedback_channel?: Maybe<GuestFeedbackChannel>;
  id?: Maybe<Scalars['Int']>;
  issue_categories?: Maybe<Array<Maybe<IssueCategory>>>;
  resolution?: Maybe<Scalars['String']>;
  store_order_id?: Maybe<Scalars['Int']>;
  store_order_line_item_issues?: Maybe<Array<Maybe<StoreOrderLineItemIssue>>>;
  submitted_by?: Maybe<Scalars['String']>;
};

export type StoreOrderIssueInput = {
  amount_refunded?: InputMaybe<Scalars['Int']>;
  auth0_user_id?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  credits?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  guest_feedback?: InputMaybe<Scalars['String']>;
  guest_feedback_channel_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  issue_category_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  resolution?: InputMaybe<Scalars['String']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
  store_order_line_item_ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type StoreOrderLineItemIssue = {
  __typename?: 'StoreOrderLineItemIssue';
  id?: Maybe<Scalars['Int']>;
  store_order_line_item_id?: Maybe<Scalars['Int']>;
  store_order_line_item_name?: Maybe<Scalars['String']>;
};

export type StoreOrderLineItemName = {
  __typename?: 'StoreOrderLineItemName';
  name?: Maybe<Scalars['String']>;
};

export type StoreRecordInput = {
  id?: InputMaybe<Scalars['Int']>;
  ordinal?: InputMaybe<Scalars['Int']>;
};

export type StoreStatus = {
  __typename?: 'StoreStatus';
  details?: Maybe<Scalars['String']>;
  is_open?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
};

export type StoreV2 = {
  __typename?: 'StoreV2';
  brand?: Maybe<BrandV2>;
  deactivated_count?: Maybe<Scalars['Int']>;
  doordash_store_id?: Maybe<Scalars['String']>;
  doordash_store_status?: Maybe<StoreStatus>;
  grubhub_store_id?: Maybe<Scalars['String']>;
  grubhub_store_status?: Maybe<StoreStatus>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_open?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationV2>;
  menus?: Maybe<Array<Maybe<MenuV2>>>;
  ordinal?: Maybe<Scalars['Int']>;
  start_timestamp?: Maybe<Scalars['String']>;
  store_hours?: Maybe<Array<Maybe<StoreHours>>>;
  ubereats_store_id?: Maybe<Scalars['String']>;
  ubereats_store_status?: Maybe<StoreStatus>;
  updated_at?: Maybe<Scalars['Float']>;
};


export type StoreV2Doordash_Store_StatusArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
};


export type StoreV2Grubhub_Store_StatusArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
};


export type StoreV2Ubereats_Store_StatusArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
};

export type StoredPaymentMethod = {
  __typename?: 'StoredPaymentMethod';
  brand?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['String']>;
  is_default?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  stripe_payment_method_id?: Maybe<Scalars['String']>;
};

export type StripeConnectionToken = {
  __typename?: 'StripeConnectionToken';
  secret?: Maybe<Scalars['String']>;
};

export type StripeLocation = {
  __typename?: 'StripeLocation';
  id?: Maybe<Scalars['String']>;
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  intent?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
};

export type StripeReader = {
  __typename?: 'StripeReader';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type StripeTerminalReader = {
  __typename?: 'StripeTerminalReader';
  device_sw_version?: Maybe<Scalars['String']>;
  device_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['Int']>;
  reader_network_status?: Maybe<ReaderNetworkStatus>;
  serial_number?: Maybe<Scalars['String']>;
  stripe_terminal_id?: Maybe<Scalars['String']>;
};

export type SubRecipe = {
  __typename?: 'SubRecipe';
  quantity?: Maybe<Scalars['Float']>;
  recipe?: Maybe<Array<Maybe<Recipe>>>;
  uom_dimension?: Maybe<Scalars['String']>;
  uom_name?: Maybe<Scalars['String']>;
};

export type SubmitSentSurveyQuestionResponse = {
  id: Scalars['Int'];
  response_text?: InputMaybe<Scalars['String']>;
  response_value?: InputMaybe<Scalars['Int']>;
  short_id: Scalars['String'];
};

export type SyncCustomerInput = {
  customer_id?: InputMaybe<Scalars['Int']>;
};

export type SyncMenuOutput = {
  __typename?: 'SyncMenuOutput';
  status?: Maybe<Scalars['String']>;
};

export type SyncRevenueCentersOutput = {
  __typename?: 'SyncRevenueCentersOutput';
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Tag = {
  __typename?: 'Tag';
  collection_name?: Maybe<Scalars['String']>;
  hero_image?: Maybe<Image>;
  hero_image_v2?: Maybe<Image>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_collection?: Maybe<Scalars['Boolean']>;
  is_v2?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Image>;
  logo_v2?: Maybe<Image>;
  menu_item_tags?: Maybe<Array<Maybe<MenuItemTags>>>;
  menu_items?: Maybe<Array<Maybe<PublicMenuItem>>>;
  name?: Maybe<Scalars['String']>;
  ordinal?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type TagMenu_ItemsArgs = {
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
  location_slug?: InputMaybe<Scalars['String']>;
};

export type TagBulkUpdateInput = {
  id: Scalars['Int'];
  ordinal: Scalars['Int'];
};

export enum TargetType {
  MenuItem = 'menu_item',
  ModifierItem = 'modifier_item'
}

export type ThirdPartyCredentialsInput = {
  cookie?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type TicketV2 = {
  __typename?: 'TicketV2';
  custom_chit_tickets?: Maybe<Array<Maybe<CustomChitTicket>>>;
  id?: Maybe<Scalars['Int']>;
  is_completed?: Maybe<Scalars['Boolean']>;
  is_new_user?: Maybe<Scalars['Boolean']>;
  line_item?: Maybe<LineItemV2>;
  provider?: Maybe<ProviderV2>;
};

export type TipAmount = {
  __typename?: 'TipAmount';
  tipRate: Scalars['Int'];
  tipValue: Scalars['Int'];
};

export type TipAndRate = {
  __typename?: 'TipAndRate';
  tipRate: Scalars['Int'];
  tipValue: Scalars['Int'];
};

export type TipTotal = {
  __typename?: 'TipTotal';
  kiosk_total?: Maybe<Scalars['Float']>;
  local_kitchens_total?: Maybe<Scalars['Float']>;
  location?: Maybe<LocationV2>;
  revel_total?: Maybe<Scalars['Float']>;
};

export type ToastMenuOutput = {
  __typename?: 'ToastMenuOutput';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UnclaimedOrder = {
  __typename?: 'UnclaimedOrder';
  id?: Maybe<Scalars['Int']>;
  inserted_at?: Maybe<Scalars['String']>;
  loyalty_points?: Maybe<Scalars['Int']>;
  provider_id?: Maybe<Scalars['Int']>;
  store_order_id?: Maybe<Scalars['Int']>;
};

export type UpdateCartInput = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  delivery_address_id?: InputMaybe<Scalars['String']>;
  dining_option?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  is_delivery?: InputMaybe<Scalars['Boolean']>;
  is_group_order?: InputMaybe<Scalars['Boolean']>;
  is_scheduled?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order_time?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  pickup_type?: InputMaybe<PickupType>;
  spend_limit?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  tip?: InputMaybe<Scalars['Int']>;
  tip_rate?: InputMaybe<Scalars['Int']>;
  use_bag?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCateringOrderInput = {
  customer_delivery_fee?: InputMaybe<Scalars['Int']>;
  discount?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  invoice_paid_at?: InputMaybe<Scalars['String']>;
  service_fee?: InputMaybe<Scalars['Int']>;
  service_fee_rate?: InputMaybe<Scalars['Int']>;
  subtotal?: InputMaybe<Scalars['Int']>;
  tax?: InputMaybe<Scalars['Int']>;
  tax_rate?: InputMaybe<Scalars['Int']>;
  tip?: InputMaybe<Scalars['Int']>;
  tip_rate?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
};

export type UpdateCouponInput = {
  brand_attrs?: InputMaybe<Scalars['JSON']>;
  code?: InputMaybe<Scalars['String']>;
  coupon_id?: InputMaybe<Scalars['Int']>;
  coupon_type?: InputMaybe<Scalars['String']>;
  credits?: InputMaybe<Scalars['Int']>;
  discount_rate?: InputMaybe<Scalars['Int']>;
  display_name?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['String']>;
  free_item_minimum_subtotal?: InputMaybe<Scalars['Int']>;
  free_item_ranks?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_fundraiser?: InputMaybe<Scalars['Boolean']>;
  location_attrs?: InputMaybe<Scalars['JSON']>;
  loyalty_account_attrs?: InputMaybe<Scalars['JSON']>;
  max_amount?: InputMaybe<Scalars['Int']>;
  minimum_cart_items?: InputMaybe<Scalars['Int']>;
  minimum_subtotal?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order_history_attrs?: InputMaybe<Scalars['JSON']>;
  users_subset?: InputMaybe<UsersSubset>;
};

export type UpdateCustomerInput = {
  auth0_user_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expo_push_token?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  location_id?: InputMaybe<Scalars['Int']>;
  order_type?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
};

export type UpdateItemInput = {
  cart_id?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  item?: InputMaybe<Scalars['JSON']>;
};

export type UpdateKioskCartInput = {
  customer_id?: InputMaybe<Scalars['Int']>;
  dining_option?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  last_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order_time?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tip?: InputMaybe<Scalars['Int']>;
  tip_rate?: InputMaybe<Scalars['Int']>;
};

export type UpdateLocationInput = {
  are_drinks_self_serve?: InputMaybe<Scalars['Boolean']>;
  google_place_id?: InputMaybe<Scalars['String']>;
  guest_experience_phone_number?: InputMaybe<Scalars['String']>;
  is_dine_in_available?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  share_link?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  stripe_location_id?: InputMaybe<Scalars['String']>;
  tax_rate?: InputMaybe<Scalars['Int']>;
  timezone?: InputMaybe<Scalars['String']>;
  toast_location_id?: InputMaybe<Scalars['String']>;
  total_kiosks?: InputMaybe<Scalars['Int']>;
  yelp_location_id?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UpdateMarketingBillboardInput = {
  background_color: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  end_time?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  image_blurhash?: InputMaybe<Scalars['String']>;
  image_url?: InputMaybe<Scalars['String']>;
  location_ids?: InputMaybe<Array<Scalars['Int']>>;
  platforms?: InputMaybe<Array<InputMaybe<PlatformsEnum>>>;
  portrait_image?: InputMaybe<Scalars['String']>;
  portrait_image_blurhash?: InputMaybe<Scalars['String']>;
  portrait_image_url?: InputMaybe<Scalars['String']>;
  redirect?: InputMaybe<MarketingBillboardRedirectInput>;
  start_time?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  visibility?: InputMaybe<Array<InputMaybe<BannerVisibilityEnum>>>;
};

export type UpdatePickupTimeInput = {
  is_scheduled?: InputMaybe<Scalars['Boolean']>;
  pickup_time?: InputMaybe<Scalars['Float']>;
  store_order_id?: InputMaybe<Scalars['Int']>;
};

export type UpdatePickupTimeOutput = {
  __typename?: 'UpdatePickupTimeOutput';
  is_scheduled?: Maybe<Scalars['Boolean']>;
  pickup_time?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  store_order_id?: Maybe<Scalars['Int']>;
};

export type UpdateTagInput = {
  /** Base 64 Image */
  hero_image?: InputMaybe<Scalars['String']>;
  hero_image_blurhash?: InputMaybe<Scalars['String']>;
  hero_image_blurhash_v2?: InputMaybe<Scalars['String']>;
  hero_image_url?: InputMaybe<Scalars['String']>;
  hero_image_url_v2?: InputMaybe<Scalars['String']>;
  hero_image_v2?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_collection?: InputMaybe<Scalars['Boolean']>;
  is_filter?: InputMaybe<Scalars['Boolean']>;
  /** Base 64 Image */
  logo?: InputMaybe<Scalars['String']>;
  logo_blurhash?: InputMaybe<Scalars['String']>;
  logo_blurhash_v2?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  logo_url_v2?: InputMaybe<Scalars['String']>;
  logo_v2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ordinal?: InputMaybe<Scalars['Int']>;
};

export type UploadMediaInput = {
  /** Media serialized as base64 */
  content: Scalars['String'];
  mimeType: Scalars['String'];
};

export type UploadMenuItemImageInput = {
  imageBase64: Scalars['String'];
  name: Scalars['String'];
};

export enum UsersSubset {
  NewKioskUser = 'NEW_KIOSK_USER',
  Regular = 'REGULAR'
}

export type VerifyTokenOutput = {
  __typename?: 'VerifyTokenOutput';
  status?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  id?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Image>;
  url?: Maybe<Scalars['String']>;
};
